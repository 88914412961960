import { OPERATOR_RATE_FIELDS, STATE_RATE_FIELDS } from '../types';

export const STATE_PRICES_API_URLS = {
  STATE_RATES: '/recycle/state-rate',
  STATE_RATES_HISTORY: '/recycle/state-rate/history',
  OPERATOR_RATES: '/recycle/organization-rate',
  OPERATOR_RATES_HISTORY: '/recycle/organization-rate/history',
};

export const OPERATOR_NUM_OF_DECIMAL_PLACES: Record<OPERATOR_RATE_FIELDS, number> = {
  SRP: 4,
  calculationCRV: 4,
};

export const STATE_NUM_OF_DECIMAL_PLACES: Record<STATE_RATE_FIELDS, number> = {
  price: 4,
  limit: 2,
  fiveCentExchangeRate: 2,
};
