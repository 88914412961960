/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { toLocaleDateAndTimeString } from 'modules/common';
import { DashboardState } from 'modules/dashboard';

const initialState: DashboardState = {
  scannersUpdated: '',
  bagsTodayUpdated: '',
  bagsWaitingUpdated: '',
};

const handleUpdateScannersTime = (state: DashboardState) => {
  state.scannersUpdated = toLocaleDateAndTimeString(new Date().toISOString());
};
const handleUpdateBagsTodayTime = (state: DashboardState) => {
  state.bagsTodayUpdated = toLocaleDateAndTimeString(new Date().toISOString());
};
const handleUpdateBagsWaitingTime = (state: DashboardState) => {
  state.bagsWaitingUpdated = toLocaleDateAndTimeString(new Date().toISOString());
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateScannersTime: (state) => {
      handleUpdateScannersTime(state);
    },
    updateBagsTodayTime: (state) => {
      handleUpdateBagsTodayTime(state);
    },
    updateBagsWaitingTime: (state) => {
      handleUpdateBagsWaitingTime(state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateBagsTodayTime, updateBagsWaitingTime, updateScannersTime } =
  dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;

export const getScannersTime = (state: RootState) => state.dashboard.scannersUpdated;
export const getBagsTodayTime = (state: RootState) => state.dashboard.bagsTodayUpdated;
export const getBagsWaitingTime = (state: RootState) => state.dashboard.bagsWaitingUpdated;
