import { LogInfo, NoteData, ORDER, OpeningHours, PageInfo, Sorting } from 'modules/common';
import { ScanningDevice } from 'modules/devices';

export interface Address {
  city: string;
  address: string;
  zipCode: string;
}

export interface LocationWithAddress {
  title: string;
  locationUId: string;
  organizationUId?: string;
  address?: Address;
  unitAssigned?: boolean;
}

export interface LocationItem extends LocationWithAddress {
  _id: string;
  createdAt: string;
  location: object;
  openingHours: OpeningHours;
  status: string;
  type: string;
  updatedAt: string;
  unitAssigned: boolean;
  showOnMap: boolean;
  totalBagsCollected: number;
  totalBagsCollectedToday: number;
  operatorName?: string;
}

export interface LocationDetailsItem {
  _id: string;
  address: Address;
  createdAt: string;
  location: LocationResponse;
  locationUId: string;
  openingHours: OpeningHours;
  organizationUId: string;
  status: string;
  title: string;
  type: string;
  updatedAt: string;
  unitAssigned: ScanningDevice[];
  showOnMap: boolean;
  totalBagsCollected: number;
  totalBagsCollectedToday: number;
  operatorName?: string;
  noteData?: NoteData;
}

export interface LocationsList {
  meta: PageInfo;
  data: LocationItem[];
}

export type LocationsListSorting = Sorting<LOCATION_SORT_OPTION_TABLE_KEYS>;

export interface LocationsListState {
  page: number;
  sorting: LocationsListSorting;
}

export enum LOCATION_SORT_OPTION_TABLE_KEYS {
  TITLE = 'title',
  IN_SERVICE = 'inService',
  LOCATION_UID = 'locationUId',
  LOCATION_CITY = 'locationCity',
  LOCATION_ADDRESS = 'locationAddress',
  SHOW_ON_MAP = 'showOnMap',
}

export enum LOCATION_SORT_OPTIONS {
  TITLE = 'title',
  CREATED_AT = 'createdAt',
  LOCATION_UID = 'locationUId',
  ADDRESS_CITY = 'address.city',
  ADDRESS_ADDRESS = 'address.address',
  SHOW_ON_MAP = 'showOnMap',
}

export const locationTableSortOptions: Record<
  LOCATION_SORT_OPTION_TABLE_KEYS,
  LOCATION_SORT_OPTIONS
> = {
  title: LOCATION_SORT_OPTIONS.TITLE,
  inService: LOCATION_SORT_OPTIONS.CREATED_AT,
  locationUId: LOCATION_SORT_OPTIONS.LOCATION_UID,
  locationCity: LOCATION_SORT_OPTIONS.ADDRESS_CITY,
  locationAddress: LOCATION_SORT_OPTIONS.ADDRESS_ADDRESS,
  showOnMap: LOCATION_SORT_OPTIONS.SHOW_ON_MAP,
};

export interface LocationsListQueryParams {
  page?: number;
  take?: number;
  sort?: LOCATION_SORT_OPTIONS;
  order?: ORDER;
  status?: LOCATIONS_STATUSES | string;
  search?: string;
  organizationUId?: string;
}

export enum LOCATIONS_STATUSES {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  ARCHIVED = 'archived',
}

export interface LocationFields {
  zip: string;
  city: string;
  address: string;
  title: string;
  lat: string | number;
  lon: string | number;
  type: UNIT_TYPES;
  unit?: string;
  noteData?: string;
  organizationUId: string;
  showOnMap: boolean;
  openingHours: OpeningHours;
}

export enum UNIT_TYPES {
  DROP_OFF = 'drop_off',
  PROCESS_CENTER = 'process_center',
}

export enum ADD_LOCATION_FIELDS {
  ZIP = 'zip',
  CITY = 'city',
  ADDRESS = 'address',
  ORGANIZATIONUID = 'organizationUId',
  LAT = 'lat',
  LON = 'lon',
  UNIT_TYPE = 'type',
  UNIT = 'unit',
  NOTE_DATA = 'noteData',
  SHOW_ON_MAP = 'showOnMap',
  TITLE = 'title',
  OPENING_HOURS = 'openingHours',
}

export enum OPENING_HOURS_FIELDS {
  DATE = 'openingHours.date',
  FROM = 'openingHours.from',
  TO = 'openingHours.to',
  IS_CLOSED = 'openingHours.isClosed',
  IS_24_HOUR_OPENING = 'openingHours.is24HourOpening',
}

export interface Location {
  coordinates: {
    lon: number;
    lat: number;
  };
}
export interface LocationResponse {
  type: 'Point';
  coordinates: [number, number];
}

export interface AddNewLocationBody {
  organizationUId: string;
  location: Location;
  type: UNIT_TYPES;
  noteData?: string;
  showOnMap: boolean;
  address: Address;
  title: string;
  openingHours: OpeningHours;
}

export interface UpdateLocationBody {
  id: string;
  showOnMap?: boolean;
  status?: LOCATIONS_STATUSES;
  location?: Location;
  title?: string;
  address?: Address;
  noteData?: NoteData;
  openingHours?: OpeningHours;
}

export interface LocationChange {
  status?: LOCATIONS_STATUSES;
  showOnMap?: boolean;
  title?: string;
  lat?: string;
  lon?: string;
  address?: Address;
  openingHours?: OpeningHours;
}

export interface AddNewLocationResponse {
  _id: string;
  locationUId: string;
  organizationUId: string;
  location: LocationResponse;
  type: UNIT_TYPES;
  showOnMap: boolean;
  status: LOCATIONS_STATUSES;
  note: string;
  openingHours: OpeningHours;
  address: {
    city: string;
    zipCode: string;
    address: string;
  };
  createdAt: string;
  noteData?: NoteData;
}

export enum BAG_QUALITY_ISSUE {
  LIQUID_IN_BOTTLES = 'liquid_in_bottles',
  NON_CRV_IN_EXCESS = 'non_crv_in_excess',
  CONTAMINATED = 'contaminated',
  RIPPED = 'ripped',
  MIXED = 'mixed',
}

export interface LocationLogInfo extends LogInfo<LocationResponse> {
  id: string;
}

export interface LocationLogInfoList {
  data: LocationLogInfo[];
  meta: PageInfo;
}

export interface LocationLogsListState {
  page: number;
  locationLogs: LocationLogInfo[];
}
