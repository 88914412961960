/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { SupportContactLogState } from 'modules/support-contact/types';

import { supportContactApi } from '../api';

const initialState: SupportContactLogState = {
  page: 1,
  lastPage: 1,
  supportContactLogs: [],
};

export const supportContactLogsSlice = createSlice({
  name: 'supportContactLogs',
  initialState,
  reducers: {
    resetSupportContactLogs: (state) => {
      state.page = 1;
      state.supportContactLogs = [];
    },
    setSupportContactLogsPage: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      supportContactApi.endpoints.getSupportContactLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.lastPage = payload.meta.lastPage;
          state.supportContactLogs = state.supportContactLogs.concat(payload.data);
        } else {
          state.lastPage = payload.meta.lastPage;
          state.supportContactLogs = payload.data;
        }
      },
    );
  },
});

export const supportContactLogsReducer = supportContactLogsSlice.reducer;

export const { setSupportContactLogsPage, resetSupportContactLogs } =
  supportContactLogsSlice.actions;

export const selectSupportContactLogs = (state: RootState) =>
  state.supportContactLogs.supportContactLogs;

export const selectSupportContactLogsPage = (state: RootState) => state.supportContactLogs.page;

export const selectSupportContactLogsLastPage = (state: RootState) =>
  state.supportContactLogs.lastPage;
