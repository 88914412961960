import * as yup from 'yup';

import { StringSchemaGenerator, VALIDATION_ERRORS, getErrorMessage } from 'modules/common';
import {
  ADD_DEVICE_FIELDS,
  AddNewDeviceFields,
  SCANNING_DEVICE_STATUS,
  UpdateDeviceFields,
} from 'modules/devices/types';

export const AddNewDeviceSchema: yup.Schema<AddNewDeviceFields> = yup.object().shape({
  deviceId: StringSchemaGenerator(ADD_DEVICE_FIELDS.DEVICE_ID),
  location: yup.string(),
  accessCode: yup
    .string()
    .required(getErrorMessage(ADD_DEVICE_FIELDS.ACCESS_CODE, VALIDATION_ERRORS.REQUIRED_FIELD))
    .test(
      'Digits only',
      getErrorMessage(ADD_DEVICE_FIELDS.ACCESS_CODE, VALIDATION_ERRORS.NUMBER_FIELD) ?? '',
      (value) => /^\d+$/.test(value),
    )
    .test(
      ADD_DEVICE_FIELDS.ACCESS_CODE,
      getErrorMessage(ADD_DEVICE_FIELDS.ACCESS_CODE, VALIDATION_ERRORS.MIN_6_MAX_12) ?? '',
      (val) => val.length >= 6 && val.length <= 12,
    ),
  status: yup
    .mixed<SCANNING_DEVICE_STATUS>()
    .oneOf(Object.values(SCANNING_DEVICE_STATUS))
    .nonNullable()
    .required(getErrorMessage(ADD_DEVICE_FIELDS.STATUS, VALIDATION_ERRORS.REQUIRED_FIELD)),
  certificateUId: yup.string().when('status', ([status]) => {
    if (status === SCANNING_DEVICE_STATUS.ACTIVE) {
      return yup
        .string()
        .required(
          getErrorMessage(
            ADD_DEVICE_FIELDS.CERTIFICATE_UID,
            VALIDATION_ERRORS.ACTIVE_DEVICE_NEEDS_CERTIFICATE,
          ),
        );
    }

    return yup.string();
  }),
  organizationUId: StringSchemaGenerator(ADD_DEVICE_FIELDS.ORGANIZATIONUID),
  notes: yup.string().notRequired().nonNullable(),
});

export const UpdateDeviceSchema: yup.Schema<UpdateDeviceFields> = yup.object().shape({
  location: yup.string(),
  accessCode: yup
    .string()
    .when({
      is: (exist: unknown) => !!exist,
      then: (rule) =>
        rule.test(
          ADD_DEVICE_FIELDS.ACCESS_CODE,
          getErrorMessage(ADD_DEVICE_FIELDS.ACCESS_CODE, VALIDATION_ERRORS.MIN_6_MAX_12) ?? '',
          // The value will always exist, since we checking it in the "is" method
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (val) => val!.length >= 6 && val!.length <= 12,
        ),
    })
    .when({
      is: (exist: unknown) => !!exist,
      then: (rule) =>
        rule.test(
          'Digits only',
          getErrorMessage(ADD_DEVICE_FIELDS.ACCESS_CODE, VALIDATION_ERRORS.NUMBER_FIELD) ?? '',
          // The value will always exist, since we checking it in the "is" method
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (value) => /^\d+$/.test(value!),
        ),
    }),
  status: yup
    .mixed<SCANNING_DEVICE_STATUS>()
    .oneOf(Object.values(SCANNING_DEVICE_STATUS))
    .nonNullable(),
  certificateUId: yup.string().when('status', ([status]) => {
    if (status === SCANNING_DEVICE_STATUS.ACTIVE) {
      return yup
        .string()
        .required(
          getErrorMessage(
            ADD_DEVICE_FIELDS.CERTIFICATE_UID,
            VALIDATION_ERRORS.ACTIVE_DEVICE_NEEDS_CERTIFICATE,
          ),
        );
    }

    return yup.string();
  }),
});
