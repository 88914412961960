/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ProcessingFeeLogState } from 'modules/processing-fee/types';

import { processingFeeApi } from '../api';

const initialState: ProcessingFeeLogState = {
  page: 1,
  lastPage: 1,
  processingFeeLogs: [],
};

export const processingFeeLogsSlice = createSlice({
  name: 'processingFeeLogs',
  initialState,
  reducers: {
    resetProcessingFeeLogs: (state) => {
      state.page = 1;
      state.processingFeeLogs = [];
    },
    setProcessingFeeLogsPage: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      processingFeeApi.endpoints.getProcessingFeeLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.lastPage = payload.meta.lastPage;
          state.processingFeeLogs = state.processingFeeLogs.concat(payload.data);
        } else {
          state.lastPage = payload.meta.lastPage;
          state.processingFeeLogs = payload.data;
        }
      },
    );
  },
});

export const processingFeeLogsReducer = processingFeeLogsSlice.reducer;

export const { setProcessingFeeLogsPage, resetProcessingFeeLogs } = processingFeeLogsSlice.actions;

export const selectProcessingFeeLogs = (state: RootState) =>
  state.processingFeeLogs.processingFeeLogs;

export const selectProcessingFeeLogsPage = (state: RootState) => state.processingFeeLogs.page;

export const selectProcessingFeeLogsLastPage = (state: RootState) =>
  state.processingFeeLogs.lastPage;
