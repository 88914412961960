import { Toaster } from 'react-hot-toast';
import { Outlet, RouteObject } from 'react-router-dom';

import { ScrollToTop } from 'modules/common';
import {
  accountsRouter,
  activitiesRouter,
  authRouter,
  certificatesRouter,
  dashboardRouter,
  devicesRouter,
  // ipAddressesRouter,
  locationsRouter,
  notificationsRouter,
  operatorsRouter,
  pricesRouter,
  processingFeeRouter,
  reportsRouter,
  supportContactRouter,
  usersRouter,
} from 'router/ModuleRouters';

export function RootRouter(): RouteObject[] {
  return [
    {
      element: (
        <>
          <ScrollToTop />
          <Toaster />
          <Outlet />
        </>
      ),
      children: [
        ...authRouter,
        ...accountsRouter,
        ...activitiesRouter,
        ...certificatesRouter,
        ...dashboardRouter,
        ...devicesRouter,
        ...locationsRouter,
        ...notificationsRouter,
        ...operatorsRouter,
        ...pricesRouter,
        ...processingFeeRouter,
        ...reportsRouter,
        ...supportContactRouter,
        ...usersRouter,
        /* TODO IP RESTRICTION Put back once relevant, removed for the time being */
        /* ...ipAddressesRouter, */
      ],
    },
  ];
}
