/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { BagDepositedLogsListState } from 'modules/activities/types';

import { bagsDepositedApi } from '../api';

const initialState: BagDepositedLogsListState = {
  page: 1,
  bagDepositedLogs: [],
};

export const bagDepositedLogsListSlice = createSlice({
  name: 'bagDepositedLogsList',
  initialState,
  reducers: {
    resetBagDepositedLogs: (state) => {
      state.page = 1;
      state.bagDepositedLogs = [];
    },
    setBagDepositedLogsListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bagsDepositedApi.endpoints.getBagDepositedLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.bagDepositedLogs = state.bagDepositedLogs.concat(payload.data);
        } else {
          state.bagDepositedLogs = payload.data;
        }
      },
    );
  },
});

export const bagDepositedLogsListReducer = bagDepositedLogsListSlice.reducer;

export const { setBagDepositedLogsListPage, resetBagDepositedLogs } =
  bagDepositedLogsListSlice.actions;

export const selectBagDepositedLogsList = (state: RootState) => state.bagDepositedLogsList;
