import { isArray } from 'lodash-es';
import toast from 'react-hot-toast';

import i18n from 'config/translation';

interface PossibleWarning {
  isWarning: boolean;
  warningMsg?: string | null;
}

export const toastSuccessOrWarn = (
  possibleWarnings: PossibleWarning[] | PossibleWarning,
  sendSuccess = true,
) => {
  const possibleWarningAdjusted = isArray(possibleWarnings) ? possibleWarnings : [possibleWarnings];

  if (sendSuccess && possibleWarningAdjusted.every(({ isWarning }) => !isWarning)) {
    toast.success(i18n.t('common.success'));
  } else {
    possibleWarningAdjusted.forEach(({ isWarning, warningMsg }) => {
      if (isWarning) {
        toast(warningMsg || '', {
          icon: '⚠️',
          duration: 5000,
        });
      }
    });
  }
};
