import { OpeningHoursCustomDay } from 'modules/common';

export function customDateTransformerFromServer(customDays?: OpeningHoursCustomDay[]) {
  const extractRegex = /[\d]{4}-[\d]{2}-[\d]{2}/;
  return customDays?.map((day) => ({ ...day, date: extractRegex.exec(day.date)?.[0] ?? '' }));
}

export function customDateTransformerToServer(customDays?: OpeningHoursCustomDay[]) {
  return customDays?.map((day) => ({ ...day, date: `${day.date}T00:00:00.000Z` }));
}
