import { GROUP_ROLE_ACCESS, Role, USER_ROLE_KEYS } from 'modules/auth';
import { ORDER, PageInfo, QueryFilterParams, SearchAndFiltersState, Sorting } from 'modules/common';

export interface GroupOnUserList {
  _id: string;
  name: string;
}

export interface OperatorOnUserList {
  organizationUId: string;
  name: string;
  createdAt: string;
}

export interface UserItem {
  userUId: string;
  email: string;
  firstName: string;
  lastName: string;
  group?: GroupOnUserList;
  organizations?: OperatorOnUserList[];
  position: string;
  phoneNumber: string;
  createdAt: string;
}

export interface UsersList {
  meta: PageInfo;
  data: UserItem[];
}

export type UsersListSorting = Sorting<USER_SORT_OPTION_TABLE_KEYS>;

export interface UsersListState {
  page: number;
  sorting: UsersListSorting;
  searchAndFilters: SearchAndFiltersState;
}

export enum USER_SORT_OPTION_TABLE_KEYS {
  USER_SINCE = 'userSince',
  USERUID = 'userUId',
  NAME = 'name',
  EMAIL = 'email',
}

export enum USER_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  USERUID = 'userUId',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}

export const userTableSortOptions: Record<USER_SORT_OPTION_TABLE_KEYS, USER_SORT_OPTIONS> = {
  userSince: USER_SORT_OPTIONS.CREATED_AT,
  name: USER_SORT_OPTIONS.LAST_NAME,
  userUId: USER_SORT_OPTIONS.USERUID,
  email: USER_SORT_OPTIONS.EMAIL,
};

export interface UsersListQueryParams {
  page?: number;
  sort?: USER_SORT_OPTIONS;
  order?: ORDER;
  operatorUId?: string;
  filters?: QueryFilterParams;
}

export type PermissionChanges = Record<
  string,
  Record<USER_ROLE_KEYS, GROUP_ROLE_ACCESS> | Record<string, never>
>;

export enum ADD_NEW_GROUP_FIELDS {
  NAME = 'name',
  DESCRIPTION = 'description',
}

export type AddNewGroupFields = Record<ADD_NEW_GROUP_FIELDS, string>;

export enum USER_TYPES {
  CUSTOMER = 'customer',
  ADMIN = 'admin',
  SUPERUSER = 'superuser',
}

export enum USER_STATUS {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  ARCHIVE = 'archive',
}

export enum ADD_USER_FIELDS {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ZIP = 'zip',
  PHONE_NUMBER = 'phoneNumber',
  GROUP = 'groupId',
  OPERATOR = 'organizationUId',
  POSITION = 'position',
  PASSWORD = 'password',
}

export interface EditUserFields {
  [ADD_USER_FIELDS.FIRST_NAME]: string;
  [ADD_USER_FIELDS.LAST_NAME]: string;
  [ADD_USER_FIELDS.PHONE_NUMBER]: string;
  [ADD_USER_FIELDS.GROUP]: string;
  [ADD_USER_FIELDS.OPERATOR]: string;
  [ADD_USER_FIELDS.POSITION]?: string;
}

export interface AddNewUserFields extends EditUserFields {
  [ADD_USER_FIELDS.EMAIL]: string;
  [ADD_USER_FIELDS.ZIP]: string;
  [ADD_USER_FIELDS.PASSWORD]: string;
}

export type AddNewUserBody = AddNewUserFields;

export interface UserDetails {
  userUId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  status: USER_STATUS;
  type: USER_TYPES;
  createdAt: string;
  deletedAt: string;
  emailConfirmedAt: string;
  organizations: { name: string; _id?: string }[];
  userGroup?: { _id: string; name: string };
  position: string;
  password?: string;
  sendDailyActivitiesSummaryReportViaEmail: boolean;
}

export interface UserChange {
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  groupId?: string;
  groupNameForRTK?: string;
  organizations?: { name: string; _id: string }[];
  organizationUId?: string;
  organizationNameForRTK?: string;
  status?: USER_STATUS;
  position?: string;
  password?: string;
  sendDailyActivitiesSummaryReportViaEmail?: boolean;
}

export interface UserPatchRequest {
  userUId: string;
  body: UserChange;
}

export interface GroupPermission {
  _id?: string;
  name: string;
  roles: Role[];
  usersAdded?: number;
  description?: string;
}

export interface PermissionsByGroups {
  groups: GroupPermission[];
}
