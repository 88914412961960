import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const processingFeeModule = () => import('modules/processing-fee');

export const processingFeeRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.PROCESSING_FEE,
        lazy: lazyImportComponent(processingFeeModule, 'ProcessingFeeView'),
      },
    ],
  },
];
