import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const operatorsModule = () => import('modules/operators');

export const operatorsRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.OPERATORS_STATUS,
        lazy: lazyImportComponent(operatorsModule, 'OperatorList'),
      },
      {
        path: PATHS.NEW_OPERATOR,
        lazy: lazyImportComponent(operatorsModule, 'NewOperator'),
      },
      {
        path: PATHS.OPERATOR_DETAILS,
        lazy: lazyImportComponent(operatorsModule, 'OperatorDetails'),
      },
    ],
  },
];
