/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { calculateDateBack, endOfTheDay, startOfTheDay } from 'modules/common';
import { DailyActivitiesSummaryState } from 'modules/reports/types';

const initialState: DailyActivitiesSummaryState = {
  filters: {
    from: startOfTheDay(calculateDateBack(1)).toISOString(),
    until: endOfTheDay(calculateDateBack(1)).toISOString(),
  },
};

export const dailyActivitiesSummarySlice = createSlice({
  name: 'dailyActivitiesSummary',
  initialState,
  reducers: {
    setDailyActivitiesSummaryDate: (state, { payload }) => {
      state.filters.from = payload.from;
      state.filters.until = payload.until;
    },
  },
});

export const dailyActivitiesSummaryReducer = dailyActivitiesSummarySlice.reducer;

export const { setDailyActivitiesSummaryDate } = dailyActivitiesSummarySlice.actions;

export const selectDailyActivitiesSummary = (state: RootState) => state.dailyActivitiesSummary;
