import { FC } from 'react';

interface SpinnerProps {
  height?: string;
  color?: string;
}

/**
 * @param color Color used. Provide in tailwind background color format (e.g bg-neutral-900, bg-white...). bg-neutral-300 by default.
 */
export const Spinner: FC<SpinnerProps> = ({
  color = 'bg-neutral-300',
  height = 'min-h-[300px]',
}) => {
  return (
    <div className={`flex items-center justify-center h-full w-full ${height}`}>
      <span className="relative flex h-10 w-10">
        <span
          className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${color}`}
        />
      </span>
    </div>
  );
};
