import { throttle } from 'lodash-es';
import { MouseEvent, useCallback, useEffect, useRef } from 'react';

export const useOnClickThrottle = <T extends (event: MouseEvent<HTMLButtonElement>) => void>(
  onClick: T,
) => {
  const onClickRef = useRef(onClick);
  const throttledOnClickRef = useRef<((event: MouseEvent<HTMLButtonElement>) => void) | null>(null);

  const stableOnClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    onClickRef.current?.(event);
  }, []);

  useEffect(() => {
    onClickRef.current = onClick;
  }, [onClick]);

  useEffect(() => {
    throttledOnClickRef.current = throttle(stableOnClick, 1000, {
      leading: true,
      trailing: false,
    });
  }, [stableOnClick]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    throttledOnClickRef.current?.(event);
  };

  return handleClick;
};
