import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const supportContactModule = () => import('modules/support-contact');

export const supportContactRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.SUPPORT_CONTACT,
        lazy: lazyImportComponent(supportContactModule, 'SupportContactView'),
      },
    ],
  },
];
