/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { OperatorLogsListState } from 'modules/operators/types';

import { operatorsApi } from '../api';

const initialState: OperatorLogsListState = {
  page: 1,
  operatorLogs: [],
};

export const operatorLogsListSlice = createSlice({
  name: 'operatorLogsList',
  initialState,
  reducers: {
    resetOperatorLogs: (state) => {
      state.page = 1;
      state.operatorLogs = [];
    },
    setOperatorLogsListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      operatorsApi.endpoints.getOperatorLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.operatorLogs = state.operatorLogs.concat(payload.data);
        } else {
          state.operatorLogs = payload.data;
        }
      },
    );
  },
});

export const OperatorLogsListReducer = operatorLogsListSlice.reducer;

export const { setOperatorLogsListPage, resetOperatorLogs } = operatorLogsListSlice.actions;

export const selectOperatorLogsList = (state: RootState) => state.operatorLogsList;
