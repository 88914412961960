import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Schema } from 'yup';

export type ValidationError<T extends object> = Record<keyof T, string>;

export interface UseFormValidationReturn<T extends object> {
  isFormValid: boolean;
  formErrors: ValidationError<T>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  validate: () => Promise<null | {}>;
  toastFormErrors: () => void;
}

export interface UseFormReturn<T extends object> extends UseFormValidationReturn<T> {
  form: T;
  setForm: Dispatch<SetStateAction<T>>;
}

/**
 * Hook to handle the form validation by schema.
 * @param schema - The validation schema.
 * @param fields - The fields of a form.
 * @param validateOnTheFly - The validation runs after every change in fields.
 */
const useFormValidation = <T extends object>(
  schema: Schema<T>,
  fields: T,
  validateOnTheFly: boolean,
): UseFormValidationReturn<T> => {
  const { t } = useTranslation();

  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formErrors, setFormErrors] = useState<ValidationError<any>>({});

  const checkTouchedFields = useCallback(() => {
    const fieldsWithValue = Object.keys(fields).filter(
      (key) => !!(fields[key as never] as string)?.length,
    );
    if (!touchedFields.length) {
      setTouchedFields(fieldsWithValue);
      return;
    }

    const newTouchedFields = fieldsWithValue.filter((item) => !touchedFields.includes(item));
    if (newTouchedFields.length) {
      setTouchedFields((prevState) => prevState.concat(newTouchedFields));
    }
    // This callback should run only when in the effect where it is invoked
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const mapErrors = useCallback(
    (errors: Array<{ fieldName: string; error: string }>) => {
      checkTouchedFields();
      return errors.reduce((current, { fieldName, error }) => {
        if (current[fieldName as never]) {
          return current;
        }
        return { ...current, [fieldName]: error && t(error) };
      }, {});
    },
    // This callback should run only when in the effect where it is invoked

    [t, checkTouchedFields],
  );

  const validate = useCallback(async () => {
    try {
      await schema.validate(fields, { abortEarly: false });
      setIsFormValid(true);
      setFormErrors({});
      return null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const errors = mapErrors(e.errors);
      setIsFormValid(false);
      setFormErrors(errors);
      return errors;
    }
  }, [fields, schema, mapErrors]);

  const toastFormErrors = () => {
    Object.keys(formErrors).forEach((errorKey) => {
      const error = formErrors[errorKey as keyof typeof formErrors];
      toast.error(`${t(`form.${errorKey}`)}: ${error}`);
    });
  };

  useEffect(() => {
    if (validateOnTheFly) {
      validate();
    }
  }, [fields, schema, mapErrors, validate, validateOnTheFly]);

  return {
    isFormValid,
    formErrors,
    validate,
    toastFormErrors,
  };
};

/**
 * Hook for the form setter with form validation.
 * @param schema - The validation schema.
 * @param initialFields - The initial fields of a form.
 * @param validateOnTheFly - The validation runs after every change in fields.
 */
export const useForm = <T extends object>(
  schema: Schema<T>,
  initialFields: T,
  validateOnTheFly = true,
): UseFormReturn<T> => {
  const [form, setForm] = useState(initialFields);

  const { isFormValid, formErrors, validate, toastFormErrors } = useFormValidation(
    schema,
    form,
    validateOnTheFly,
  );

  return { form, setForm, isFormValid, formErrors, validate, toastFormErrors };
};
