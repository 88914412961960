export const ACCOUNTS_API_URLS = {
  BAG_QUALITY_BY_USER: (userUId: string) => `/user/bag-quality/${userUId}`,
  TRANSACTION_HISTORY_BY_USER: (userUId: string) =>
    `/admin/user/summary/transaction-history/${userUId}`,
  TRANSFER_HISTORY_BY_USER: (userUId: string) => `/admin/user/summary/transfer-history/${userUId}`,
  BAG_DISPENSE_HISTORY_BY_USER: (userUId: string) =>
    `/admin/user/summary/bag-dispense-history/${userUId}`,
  FINANCIAL_ACCOUNT: (userUId: string) => `/financial/account/${userUId}`,
  FINANCIAL_TRANSACTIONS: `/financial/account-transactions`,
  ACCOUNT_STATEMENTS: '/financial/account-statements',
  TRANSACTION_REQUESTS: '/transaction-requests',
  CREATE_TRANSACTION_REQUEST: '/transaction-requests',
  DECLINE_TRANSACTION_REQUEST: (transactionRequestId: number) =>
    `/transaction-requests/decline/${transactionRequestId}`,
  ACCEPT_TRANSACTION_REQUEST: (transactionRequestId: number) =>
    `/transaction-requests/accept/${transactionRequestId}`,
  DAILY_LIMIT_REMAINING: (userUId: string) =>
    `/transaction-requests/daily-limit-remaining/${userUId}`,
};

export const TRANSFER_FEE = 0.25;
