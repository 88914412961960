import { LogInfo, NoteData, ORDER, PageInfo, Sorting } from 'modules/common';
import { Address } from 'modules/locations';

export interface DeviceHistory {
  deviceId: string;
  type?: DEVICE_HISTORY_TYPE;
  note?: string;
  createdBy?: string;
  createdAt?: string;
}
export interface ScanningDevice {
  _id: string;
  deviceId: string;
  accessCode: string;
  locationUId: string;
  organizationUId: string;
  type: SCANNING_DEVICE_TYPE;
  blockedAt?: string;
  history?: DeviceHistory[];
  status: SCANNING_DEVICE_STATUS;
  certificateUId: string;
  createdAt?: string;
}

export enum SCANNING_DEVICE_STATUS {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  ARCHIVE = 'archive',
}

export enum SCANNING_DEVICE_TYPE {
  CONTAINER = 'container',
  BAG_SCANNER = 'bag_scanner',
}

export enum DEVICE_HISTORY_TYPE {
  COMMENT = 'comment',
  STATUS_CHANGE = 'status_change',
}

export interface DevicesListQueryParams {
  page?: number;
  take?: number;
  sort?: DEVICES_LIST_SORT_OPTIONS;
  order?: ORDER;
  status?: SCANNING_DEVICE_STATUS | string;
  operatorUId?: string;
  deviceIdSearch?: string;
}

export enum DEVICES_LIST_SORT_OPTION_TABLE_KEYS {
  IN_SERVICE = 'inService',
  TOTAL_BAGS_SCANNED = 'totalBagsScanned',
  TOTAL_BAGS_TODAY = 'totalBagsToday',
  DEVICE_ID = 'deviceId',
}

export enum DEVICES_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  TOTAL_BAGS_SCANNED = 'totalBagsScanned',
  TOTAL_BAGS_TODAY = 'totalBagsToday',
  DEVICE_ID = 'deviceId',
}
export type DevicesListSorting = Sorting<DEVICES_LIST_SORT_OPTION_TABLE_KEYS>;

export const devicesListSortOptions: Record<
  DEVICES_LIST_SORT_OPTION_TABLE_KEYS,
  DEVICES_LIST_SORT_OPTIONS
> = {
  inService: DEVICES_LIST_SORT_OPTIONS.CREATED_AT,
  totalBagsScanned: DEVICES_LIST_SORT_OPTIONS.TOTAL_BAGS_SCANNED,
  totalBagsToday: DEVICES_LIST_SORT_OPTIONS.TOTAL_BAGS_TODAY,
  deviceId: DEVICES_LIST_SORT_OPTIONS.DEVICE_ID,
};
export interface DevicesListState {
  page: number;
  sorting: DevicesListSorting;
}
export interface DeviceItem {
  _id: string;
  deviceId: string;
  locationUId: string;
  organizationUId: string;
  createdAt: string;
  blockedAt: string | null;
  history: DeviceHistory[];
  type: SCANNING_DEVICE_TYPE;
  totalBagsScanned: number;
  totalBagsToday: number;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  status: SCANNING_DEVICE_STATUS;
  certificateUId: string;
  noteData?: NoteData;
}

export interface DevicesList {
  meta: PageInfo;
  data: DeviceItem[];
}

export interface DeviceAccessCodeChange {
  accessCode?: string;
}

export interface DeviceChange extends DeviceAccessCodeChange {
  status?: SCANNING_DEVICE_STATUS;
  locationUId?: string;
  locationForRTK?: Address;
  certificateUId?: string;
  noteData?: NoteData;
}

export interface UpdateDeviceBody extends DeviceChange {
  id: string;
}

export interface UpdateDeviceAccessCodeBody extends DeviceAccessCodeChange {
  id: string;
}

export interface UpdateDeviceResponse {
  deviceId: string;
  locationUId: string;
  organizationUId: string;
  type: SCANNING_DEVICE_TYPE;
  status: SCANNING_DEVICE_STATUS;
  certificateUId: string;
  blockedAt: string;
}

export enum ADD_DEVICE_FIELDS {
  ORGANIZATIONUID = 'organizationUId',
  DEVICE_ID = 'deviceId',
  CERTIFICATE_UID = 'certificateUId',
  STATUS = 'status',
  LOCATION = 'location',
  NOTE_DATA = 'noteData',
  ACCESS_CODE = 'accessCode',
}

export interface AddNewDeviceFields {
  organizationUId?: string;
  deviceId: string;
  certificateUId?: string;
  location?: string;
  status: SCANNING_DEVICE_STATUS;
  accessCode?: string;
  noteData?: string;
}
export interface UpdateDeviceFields {
  certificateUId?: string;
  locationUId?: string;
  status?: SCANNING_DEVICE_STATUS;
  accessCode?: string;
}
export interface AddNewDeviceBody {
  deviceId: string;
  accessCode: string;
  locationUId?: string;
  organizationUId?: string;
  type: SCANNING_DEVICE_TYPE;
  status: SCANNING_DEVICE_STATUS;
  certificateUId?: string;
  noteData?: string;
}

export interface AddNewDeviceResponse {
  deviceId: string;
  locationUId: string;
  organizationUId: string;
  type: SCANNING_DEVICE_TYPE;
  status: SCANNING_DEVICE_STATUS;
  certificate: string;
}

export interface DeviceLogInfo extends LogInfo<DeviceItem> {
  id: string;
}

export interface DeviceLogInfoList {
  data: DeviceLogInfo[];
  meta: PageInfo;
}

export interface DeviceLogsListState {
  page: number;
  deviceLogs: DeviceLogInfo[];
}
