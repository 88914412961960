/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, useMemo } from 'react';

import { useOnClickThrottle } from 'modules/common';
import { Image } from 'modules/common/components';

interface ButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  type?: 'button' | 'submit';
  label: string;
  /** @summary Disables spacer span on the left side of the label and sets left alignment on the text */
  alignLabelLeft?: boolean;
  /**
   * @summary Directly add styling to the label text element
   * @example <caption>Increase space between text and icon</caption>
   * labelClassName="pr-2"
   */
  labelClassName?: string;
  /**
   * @summary Forces button label to always take up the same space as if it was bold. Prevents resizing if label has `hover:bold` or similar toggling.
   * @description Adds a data-content attribute to the text, then uses an ::after style to create an invisible bolded text from it under the real label.
   */
  reserveBoldLabelSpace?: boolean;
  img?: string;
  throttling?: boolean;
}

export const Button: FC<ButtonProps> = ({
  className,
  type = 'button',
  label,
  img,
  children,
  throttling = false,
  alignLabelLeft = false,
  reserveBoldLabelSpace = false,
  labelClassName,
  onClick,
  ...props
}) => {
  const styling = `cursor-pointer rounded  ${className ?? ''}`;

  const onClickThrottled = useOnClickThrottle(onClick || (() => {}));

  const labelStyle = useMemo(
    () =>
      [
        labelClassName,
        alignLabelLeft ? 'text-left' : 'text-center grow',
        reserveBoldLabelSpace && 'reserve-bold-space',
      ].join(' '),
    [labelClassName, alignLabelLeft, reserveBoldLabelSpace],
  );

  return (
    <button
      className={styling}
      type={type}
      onClick={throttling ? onClickThrottled : onClick}
      {...props}
    >
      <div className="flex flex-row gap-0.5 mx-1 object-cover justify-stretch">
        {label && (
          <>
            {(children || img) && !alignLabelLeft && <span className="grow min-w-[13px] basis-0" />}
            <p className={labelStyle} data-content={reserveBoldLabelSpace ? label : null}>
              {label}
            </p>
          </>
        )}
        {children && (
          <span className="flex grow min-w-[13px] basis-0 my-auto justify-end">{children}</span>
        )}
        {img && <Image src={img} alt={label} className="h-[13px] w-[13px] fill-current m-auto" />}
      </div>
    </button>
  );
};
