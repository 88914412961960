import {
  LogInfo,
  NoteData,
  NotificationOnLog,
  ORDER,
  PageInfo,
  QueryFilterParams,
  SearchAndFiltersState,
  Sorting,
  UserInfo,
} from 'modules/common';
import { BAG_QUALITY_ISSUE } from 'modules/locations';
import { USER_STATUS } from 'modules/users';

export enum CUSTOMER_PAYMENT_SERVICE_TYPE {
  PAYPAL = 'PAYPAL',
  VENMO = 'VENMO',
}
export interface CustomerPayoutSettingsItem {
  service: CUSTOMER_PAYMENT_SERVICE_TYPE;
  default: boolean;
  createdAt: string;
}

export interface AccountItem {
  userUId: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  createdAt: string;
  zipCode?: string;
  wallet?: string;
  totalBagsRecycled?: number;
}

export interface AccountsList {
  meta: PageInfo;
  data: AccountItem[];
}

export type AccountsListSorting = Sorting<ACCOUNTS_LIST_SORT_OPTION_TABLE_KEYS>;

export interface AccountsListState {
  page: number;
  sorting: AccountsListSorting;
  searchAndFilters: SearchAndFiltersState;
}

export interface AccountLogInfo extends LogInfo<AccountDetails> {
  userUId: string;
  notification?: NotificationOnLog;
  userInfo?: UserInfo;
}

export interface AccountLogsListState {
  page: number;
  accountLogs: AccountLogInfo[];
}

export enum ACCOUNTS_LIST_SORT_OPTION_TABLE_KEYS {
  ACCOUNT_SINCE = 'accountSince',
  USERUID = 'userUId',
  NAME = 'name',
  EMAIL = 'email',
}

export enum ACCOUNTS_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  USERUID = 'userUId',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}

export const accountsListSortOptions: Record<
  ACCOUNTS_LIST_SORT_OPTION_TABLE_KEYS,
  ACCOUNTS_LIST_SORT_OPTIONS
> = {
  accountSince: ACCOUNTS_LIST_SORT_OPTIONS.CREATED_AT,
  name: ACCOUNTS_LIST_SORT_OPTIONS.LAST_NAME,
  userUId: ACCOUNTS_LIST_SORT_OPTIONS.USERUID,
  email: ACCOUNTS_LIST_SORT_OPTIONS.EMAIL,
};

export interface AccountChange {
  firstName?: string;
  lastName?: string;
  zipCode?: string;
  phoneNumber?: string;
  status?: USER_STATUS;
  noteData?: NoteData;
}

export interface AccountPatchRequest {
  userUId: string;
  body: AccountChange;
}

export interface AccountsListQueryParams {
  take?: number;
  page?: number;
  sort?: ACCOUNTS_LIST_SORT_OPTIONS;
  order?: ORDER;
  status?: string;
  filters?: QueryFilterParams;
}

// TODO address and city fields QD-1287
export interface AccountDetails {
  userUId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  status: string;
  createdAt: string;
  deletedAt: string;
  emailConfirmedAt: string;
  zipCode: string;
  noteData?: NoteData;
  payoutSettings?: CustomerPayoutSettingsItem[];
}
export type AccountWalletDetailsListSorting =
  Sorting<ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTION_TABLE_KEYS>;

export enum REFERENCE_TYPES {
  PROCESSED_BAG = 'PROCESSED_BAG',
  PAYOUT = 'PAYOUT',
}
export interface AccountWalletDetailsItem {
  id: number;
  createdAt: string;
  withdrawal: number;
  deposit: number;
  balance: number;
  transactionId: number;
}
export interface AccountWalletDetailsList {
  meta: PageInfo;
  data: AccountWalletDetailsItem[];
}

export enum ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTION_TABLE_KEYS {
  TIMESTAMP = 'timestamp',
}

export enum ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
}

export interface AccountWalletDetailsListState {
  page: number;
  sorting: AccountWalletDetailsListSorting;
  searchAndFilters: SearchAndFiltersState;
}
export interface AccountWalletDetailsListQueryParams {
  userUId: string;
  page?: number;
  take?: number;
  sort?: ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTIONS;
  order?: ORDER;
  filters?: QueryFilterParams;
}
export const accountWalletDetailsListSortOptions: Record<
  ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTION_TABLE_KEYS,
  ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTIONS
> = {
  timestamp: ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTIONS.CREATED_AT,
};

export interface WalletSummaryByUser {
  balance: number;
  reserved: number;
  availableBalance: number;
  updatedAt: string;
}

export interface BagQualityByUser {
  bagId: string;
  qualityIssues: BAG_QUALITY_ISSUE[];
  createdAt: string;
}

export interface FinancialAccount {
  balance: number;
  reserved: number;
  availableBalance: number;
  updatedAt: string;
}

export interface BagTransactionHistoryByUser {
  lastProcessedBagAmount: number;
  lastSessionBags: number;
  lastSessionDate: string;
  totalBagsRecycled: number;
  totalValueRedeemed: number;
}

export interface TransferHistoryByUser {
  createdAt: string;
  lastAmountTransfered: number;
}

export interface BagDispenseHistoryByUser {
  charge: string | null;
  date: string;
  lastPurchase: number;
  remainingFreeBags: number;
}

export enum FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTION_TABLE_KEYS {
  CREATED_AT = 'createdAt',
  COMPLETED_AT = 'completedAt',
  REVERTED_AT = 'revertedAt',
}

export enum FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  REVERTED_AT = 'revertedAt',
  COMPLETED_AT = 'completedAt',
}

export const financialTransactionListSortOptions: Record<
  FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTION_TABLE_KEYS,
  FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTIONS
> = {
  completedAt: FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTIONS.COMPLETED_AT,
  revertedAt: FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTIONS.REVERTED_AT,
  createdAt: FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTIONS.CREATED_AT,
};
export type FinancialTransactionDetailsListSorting =
  Sorting<FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTION_TABLE_KEYS>;

export interface FinancialTransactionDetailsListState {
  page: number;
  sorting: FinancialTransactionDetailsListSorting;
  searchAndFilters: SearchAndFiltersState;
}

export interface FinancialTransactionDetailsListQueryParams {
  userUId: string;
  page?: number;
  take?: number;
  sort?: FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTIONS;
  order?: ORDER;
  filters?: QueryFilterParams;
}

export enum TRANSACTION_STATUSES {
  IN_PROGRESS = 'IN_PROGRESS',
  RESERVED = 'RESERVED',
  REVERTED = 'REVERTED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export interface FinancialTransaction {
  userUId: string;
  referenceType: REFERENCE_TYPES;
  referenceId: string;
  amount: string;
  status: TRANSACTION_STATUSES;
  createdAt: string;
  updatedAt: string;
  reservedAt: string;
  revertedAt: string;
  completedAt: string;
}
export interface FinancialTransactionList {
  data: FinancialTransaction[];
  meta: PageInfo;
}

export interface UserLogInfoList {
  data: AccountLogInfo[];
  meta: PageInfo;
}

export interface EditAccountFields {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  zipCode: string;
}

export enum TRANSACTION_REQUEST_STATUSES {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
}

export interface TransactionRequest {
  id: number;
  userUId: string;
  amount: number;
  status: TRANSACTION_REQUEST_STATUSES;
  comment: string;
  createdByUserUId: string;
  createdByFirstName: string;
  createdByLastName: string;
  revisionedByUserUId: string;
  revisionedByFirstName: string;
  revisionedByLastName: string;
  revisionComment: string;
  createdAt: string;
  revisionedAt: string;
}

export interface TransactionRequestList {
  data: TransactionRequest[];
  meta: PageInfo;
}

export interface TransactionRequestsOnAccountQueryParams {
  userUId: string;
  page?: number;
  take?: number;
  sort?: FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTIONS;
  order?: ORDER;
  filters?: QueryFilterParams;
}

export interface AddNewTransactionRequest {
  userUId: string;
  amount?: number;
  comment?: string;
}

export interface RevisionTransactionRequestBody {
  revisionComment?: string;
}

export interface RevisionTransactionRequest {
  id: number;
  body: RevisionTransactionRequestBody;
}

export interface AcceptTransactionRequestBody extends RevisionTransactionRequestBody {
  password: string;
}

export interface AcceptTransactionRequest {
  id: number;
  body: AcceptTransactionRequestBody;
}

export interface TransactionRequestAction {
  type: 'create' | 'accept' | 'decline';
  id?: number;
}

export interface TransactionRequestsOnAccountState {
  page: number;
  transactionRequests: TransactionRequest[];
}

export enum CREATE_TRANSACTION_REQUEST_FIELDS {
  AMOUNT = 'amount',
  COMMENT = 'comment',
}

export interface CreateTransactionRequestFields {
  [CREATE_TRANSACTION_REQUEST_FIELDS.AMOUNT]?: number;
  [CREATE_TRANSACTION_REQUEST_FIELDS.COMMENT]?: string;
}

export enum DECLINE_TRANSACTION_REQUEST_FIELDS {
  REVISION_COMMENT = 'revisionComment',
}

export interface DeclineTransactionRequestFields {
  [DECLINE_TRANSACTION_REQUEST_FIELDS.REVISION_COMMENT]?: string;
}

export enum ACCEPT_TRANSACTION_REQUEST_FIELDS {
  REVISION_COMMENT = 'revisionComment',
  PASSWORD = 'password',
}

export interface AcceptTransactionRequestFields extends DeclineTransactionRequestFields {
  [ACCEPT_TRANSACTION_REQUEST_FIELDS.PASSWORD]: string;
}

export interface DailyLimitRemaining {
  dailyLimit: number;
  nonDeclinedRequestedAmountToday: number;
  dailyLimitRemaining: number;
}
