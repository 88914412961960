import { SearchAndFiltersState } from 'modules/common/types';

import { version } from '../../../../package.json';

export const VERSION_NUMBER = version;
export const BUILD_NUMBER = import.meta.env.VITE_APP_BUILD_NUMBER;

export const emptySearchAndFilters: SearchAndFiltersState = {
  filterText: '',
  filterCheckbox: [],
  isOptionsVisible: false,

  addFilter: {
    paid: null,
    bag: null,
    bagsStatus: null,
    droppedBagLocation: null,
    dateRangeType: null,
    dateRange: null,
    unpaidBags: null,
    transactionRequests: null,
    deviceId: null,
  },
};

export const ALL = 'all';

export const DEFAULT_POLLING_INTERVAL = 10000;

export const COORDINATE_INDEXES = {
  lon: 0,
  lat: 1,
};

export const COORDINATE_THRESHOLDS = {
  lon: 180,
  lat: 90,
};

export const DISABLED_BUTTON_STYLE = 'hover:cursor-not-allowed bg-neutral-400';
