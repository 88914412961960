/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import {
  BAGS_DEPOSITED_SORT_OPTION_TABLE_KEYS,
  BagsDepositedListState,
} from 'modules/activities/types';
import { emptySearchAndFilters, ORDER } from 'modules/common';

const initialState: BagsDepositedListState = {
  page: 1,
  sorting: { sort: BAGS_DEPOSITED_SORT_OPTION_TABLE_KEYS.ACCEPTED_AT, order: ORDER.DESC },
  searchAndFilters: { ...emptySearchAndFilters },
};

export const bagsDepositedListSlice = createSlice({
  name: 'bagsDepositedList',
  initialState,
  reducers: {
    setBagsDepositedListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setBagsDepositedListPage: (state, { payload }) => {
      state.page = payload;
    },
    setBagsDepositedListSearchAndFilters: (state, { payload }) => {
      state.searchAndFilters = payload;
    },
  },
});

export const bagsDepositedListReducer = bagsDepositedListSlice.reducer;

export const {
  setBagsDepositedListSorting,
  setBagsDepositedListPage,
  setBagsDepositedListSearchAndFilters,
} = bagsDepositedListSlice.actions;

export const selectBagsDepositedList = (state: RootState) => state.bagsDepositedList;
