import * as yup from 'yup';

import { EmailSchema, VALIDATION_ERRORS, getErrorMessage } from 'modules/common';
import { OPENING_HOURS_FIELDS } from 'modules/locations';
import {
  SUPPORT_CONTACT_FIELDS,
  SupportContactForm,
  SupportOpeningHours,
} from 'modules/support-contact/types';

export const SupportOpeningHoursSchema: yup.Schema<SupportOpeningHours> = yup.object().shape({
  from: yup.number().required(),
  to: yup
    .number()
    .when('from', ([from], schema) =>
      schema.min(
        from,
        getErrorMessage(OPENING_HOURS_FIELDS.TO, VALIDATION_ERRORS.CLOSING_MUST_BE_AFTER_OPENING),
      ),
    )
    .required(),
});

export const SupportContactSchema: yup.Schema<SupportContactForm> = yup.object().shape({
  supportOpeningHours: SupportOpeningHoursSchema,
  email: EmailSchema,
  phoneNumber: yup
    .string()
    .required(
      getErrorMessage(SUPPORT_CONTACT_FIELDS.PHONE_NUMBER, VALIDATION_ERRORS.REQUIRED_FIELD),
    )
    .test(
      SUPPORT_CONTACT_FIELDS.PHONE_NUMBER,
      getErrorMessage(SUPPORT_CONTACT_FIELDS.PHONE_NUMBER, VALIDATION_ERRORS.PHONE_NUMBER) ?? '',
      (value) => /^\d+$/.test(value),
    )
    .test(
      SUPPORT_CONTACT_FIELDS.PHONE_NUMBER,
      getErrorMessage(SUPPORT_CONTACT_FIELDS.PHONE_NUMBER, VALIDATION_ERRORS.PHONE_NUMBER) ?? '',
      (val) => val.length === 10,
    ),
});
