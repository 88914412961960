/* eslint-disable react/jsx-props-no-spreading */
import { Suspense, lazy } from 'react';
import { DateRangePickerProps, defaultStaticRanges } from 'react-date-range';

import { Spinner } from 'modules/common';

const MIN_DATE = new Date('2024-01-01');
const MAX_DATE = new Date();

const DateRangePickerComponent = lazy(async () => {
  await import('react-date-range/dist/styles.css'); // main style file
  await import('react-date-range/dist/theme/default.css'); // theme css file
  const module = await import('react-date-range');
  return { default: module.DateRangePicker };
});

/** Lazy loading wrapper around react-date-range's DateRangePicker for code splitting */
export const DateRangePicker: React.FC<DateRangePickerProps> = ({ ...props }) => {
  const customStaticRanges = defaultStaticRanges.filter(
    (range) => range.label !== 'Yesterday' && range.label !== 'Today',
  );

  return (
    <Suspense fallback={<Spinner />}>
      <DateRangePickerComponent
        {...props}
        minDate={MIN_DATE}
        maxDate={MAX_DATE}
        staticRanges={customStaticRanges}
        inputRanges={[]}
      />
    </Suspense>
  );
};
