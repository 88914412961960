/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ORDER } from 'modules/common';
import { OPERATORS_LIST_SORT_OPTION_TABLE_KEYS, OperatorsListState } from 'modules/operators/types';

const initialState: OperatorsListState = {
  page: 1,
  sorting: { sort: OPERATORS_LIST_SORT_OPTION_TABLE_KEYS.SIGNED_UP, order: ORDER.DESC },
};

export const operatorsListSlice = createSlice({
  name: 'operatorsList',
  initialState,
  reducers: {
    setOperatorsListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setOperatorsListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const operatorsListReducer = operatorsListSlice.reducer;

export const { setOperatorsListSorting, setOperatorsListPage } = operatorsListSlice.actions;

export const selectOperatorsList = (state: RootState) => state.operatorsList;
