/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import {
  FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTION_TABLE_KEYS,
  FinancialTransactionDetailsListState,
} from 'modules/accounts/types';
import { ORDER, emptySearchAndFilters } from 'modules/common';

const initialState: FinancialTransactionDetailsListState = {
  page: 1,
  sorting: {
    sort: FINANCIAL_TRANSACTION_DETAILS_LIST_SORT_OPTION_TABLE_KEYS.CREATED_AT,
    order: ORDER.DESC,
  },
  searchAndFilters: emptySearchAndFilters,
};
export const financialTransactionDetailsListSlice = createSlice({
  name: 'financialTransactionDetailsList',
  initialState,
  reducers: {
    setFinancialTransactionDetailsListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setFinancialTransactionDetailsListPage: (state, { payload }) => {
      state.page = payload;
    },
    setFinancialTransactionDetailsListSearchAndFilters: (state, { payload }) => {
      state.searchAndFilters = payload;
    },
  },
});
export const financialTransactionDetailsListReducer = financialTransactionDetailsListSlice.reducer;

export const {
  setFinancialTransactionDetailsListPage,
  setFinancialTransactionDetailsListSorting,
  setFinancialTransactionDetailsListSearchAndFilters,
} = financialTransactionDetailsListSlice.actions;

export const selectFinancialTransactionDetailsList = (state: RootState) =>
  state.financialTransactionDetailsList;
