import { FC } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import { getIsLoggedIn } from 'modules/auth';
import { useAppSelector } from 'modules/common';
import { ExportContainer } from 'modules/export';
import { PATHS } from 'router/utils';

export const ProtectedRoute: FC = () => {
  const location = useLocation();
  const isLoggedIn = useAppSelector(getIsLoggedIn);

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={PATHS.LOGIN} state={{ from: location }} replace />;
  }

  return (
    <>
      <ExportContainer />
      <Outlet />
    </>
  );
};
