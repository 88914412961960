import { quickDropAdminApi } from 'config/api';
import { ACTIVITIES_API_URLS } from 'modules/activities/constants';
import { resetBagDepositedLogs, setBagDepositedLogsListPage } from 'modules/activities/store';
import {
  BagsDepositedList,
  BagsDepositedDetailsType,
  BagsDepositedListQueryParams,
  BagDepositedLogInfoList,
  BagDepositedLogInfo,
  BagsDepositedNoteUpdateRequest,
} from 'modules/activities/types';
import {
  AddCustomerOrDeviceToBagRequest,
  CommentPostRequest,
  LogInfoQueryParams,
  REQUEST_METHODS,
} from 'modules/common';

export const bagsDepositedApi = quickDropAdminApi
  .enhanceEndpoints({
    addTagTypes: ['BagsDeposited', 'BagDepositedLogInfo', 'Reports'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBagsDeposited: builder.query<BagsDepositedList, BagsDepositedListQueryParams>({
        query: ({ page, sort, order, filters, operatorUId }) => {
          const params = {
            ...filters,
            'filters[organizationUId][$in][]': operatorUId,
            'pagination[page]': page,
            [`sort[${sort}]`]: order,
          };

          return {
            url: ACTIVITIES_API_URLS.DROPPED_BAGS_LIST,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (result ? ['BagsDeposited'] : []),
      }),
      getBagsDepositedById: builder.query<BagsDepositedDetailsType, string>({
        query: (id) => ({
          url: ACTIVITIES_API_URLS.DROPPED_BAG_DETAILS(id),
          method: REQUEST_METHODS.GET,
        }),
        providesTags: (_result, error) => (error ? [] : ['BagsDeposited']),
      }),
      getBagDepositedLogInfo: builder.query<BagDepositedLogInfoList, LogInfoQueryParams>({
        query: ({ page, id }) => {
          const params = {
            'pagination[page]': page,
            'filters[id]': id,
          };
          return {
            url: ACTIVITIES_API_URLS.BAG_DEPOSITED_LOG_INFO,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['BagDepositedLogInfo']),
      }),
      addBagDepositedComment: builder.mutation<BagDepositedLogInfo, CommentPostRequest>({
        query: (body) => ({
          url: ACTIVITIES_API_URLS.BAG_DEPOSITED_LOG_INFO,
          body,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : ['BagDepositedLogInfo', 'BagsDeposited'],
      }),
      updateBagDepositedNotes: builder.mutation<
        BagsDepositedDetailsType,
        BagsDepositedNoteUpdateRequest
      >({
        query: ({ id, body }) => ({
          url: ACTIVITIES_API_URLS.DROPPED_BAG_NOTE_UPDATE(id),
          body,
          method: REQUEST_METHODS.PATCH,
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : ['BagsDeposited', 'BagDepositedLogInfo'],
        onQueryStarted({ id, body: update }, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            bagsDepositedApi.util.updateQueryData('getBagsDepositedById', id, (results) => {
              return {
                ...results,
                ...update,
                droppedBag: { ...results.droppedBag, noteData: update.noteData },
              };
            }),
          );
          queryFulfilled
            .then(() => {
              dispatch(setBagDepositedLogsListPage(1));
              dispatch(resetBagDepositedLogs());
            })
            .catch(updatedResult.undo);
        },
      }),
      addCustomerOrDeviceToBag: builder.mutation<
        BagsDepositedDetailsType,
        AddCustomerOrDeviceToBagRequest
      >({
        query: (body) => ({
          url: ACTIVITIES_API_URLS.ADD_CUSTOMER_OR_DEVICE_TO_BAG,
          body,
          method: REQUEST_METHODS.PATCH,
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : ['BagsDeposited', 'Reports', 'BagDepositedLogInfo'],
      }),
    }),
  });

export const {
  useGetBagsDepositedQuery,
  useGetBagsDepositedByIdQuery,
  useLazyGetBagDepositedLogInfoQuery,
  useAddBagDepositedCommentMutation,
  useUpdateBagDepositedNotesMutation,
  useAddCustomerOrDeviceToBagMutation,
} = bagsDepositedApi;
