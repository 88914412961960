/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ORDER, emptySearchAndFilters } from 'modules/common';
import { IP_ADDRESS_LIST_SORT_OPTIONS, IpAddressListState } from 'modules/ip-addresses/types';

const initialState: IpAddressListState = {
  page: 1,
  sorting: { sort: IP_ADDRESS_LIST_SORT_OPTIONS.CREATED_AT, order: ORDER.DESC },
  searchAndFilters: { ...emptySearchAndFilters },
};

export const ipAddressesList = createSlice({
  name: 'ipAddressesList',
  initialState,
  reducers: {
    setIpAddressesListPage: (state, { payload }) => {
      state.page = payload;
    },
    setIpAddressesListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setIpAddressesListSearchAndFilters: (state, { payload }) => {
      state.searchAndFilters = payload;
    },
  },
});

export const ipAddressesListReducer = ipAddressesList.reducer;

export const {
  setIpAddressesListPage,
  setIpAddressesListSorting,
  setIpAddressesListSearchAndFilters,
} = ipAddressesList.actions;

export const selectIpAddressesList = (state: RootState) => state.ipAddressesList;
