import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { camelCase } from 'lodash-es';
import { toast } from 'react-hot-toast';

import { ApiVersionResponseError } from 'modules/api-version';
import { setCredentials, logout, RefreshTokenResponse } from 'modules/auth';
import {
  IP_RESTRICTION_CAUSE,
  REQUEST_METHODS,
  convertErrorMessagesArrayToString,
  isErrorWithMessage,
  isErrorWithMessageArray,
} from 'modules/common';

import { AUTH_API_URLS } from '../modules/auth/constants';

import type { RootState } from './store';
import i18n from './translation';

interface ExtraOptions {
  disableDefaultSuccessToaster?: boolean;
  showErrorMessage?: boolean;
}

type QuickDropBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError & { data: (string | unknown) | { cause?: string } },
  ExtraOptions,
  FetchBaseQueryMeta
>;

const AUTHORIZATION_HEADER_KEY = 'authorization';

const mutex = new Mutex();

const backendUrl = import.meta.env.VITE_APP_API_URL;

const protectedURLsReauth = [
  AUTH_API_URLS.TWO_FACTOR_AUTH_REQUEST,
  AUTH_API_URLS.LOGIN,
  AUTH_API_URLS.FORGOT_PASSWORD,
  AUTH_API_URLS.REFRESH_AUTH,
];

const protectedURLs = [...protectedURLsReauth, AUTH_API_URLS.LOGOUT];

const baseQuery = fetchBaseQuery({
  baseUrl: backendUrl,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth;
    if (accessToken && !headers.has(AUTHORIZATION_HEADER_KEY)) {
      headers.set(AUTHORIZATION_HEADER_KEY, `Bearer ${accessToken}`);
    }
    return headers;
  },
  credentials: 'include',
  mode: 'cors',
});

const baseQueryWithSuccessToaster: QuickDropBaseQuery = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.data) {
    if (!extraOptions?.disableDefaultSuccessToaster) {
      if (result.meta?.request.method === REQUEST_METHODS.PATCH && !result.error) {
        toast.success(i18n.t('common.success'));
      }

      if (!extraOptions?.disableDefaultSuccessToaster) {
        if (result.meta?.request.method === REQUEST_METHODS.DELETE && !result.error) {
          toast.success(i18n.t('common.successDelete'));
        }

        if (result.meta?.request.method === REQUEST_METHODS.POST && !result.error) {
          const url = new URL(result.meta.request.url);
          if (!protectedURLs.includes(url.pathname)) {
            toast.success(i18n.t('common.success'));
          }
        }
      }
    }
  }
  return result;
};

const baseQueryWithCamelCaseResponse: QuickDropBaseQuery = async (args, api, extraOptions) => {
  const result = await baseQueryWithSuccessToaster(args, api, extraOptions);

  if (result.data) {
    result.data = Object.entries(result.data).reduce(
      (acc, [key, value]) => ({ ...acc, [camelCase(key)]: value }),
      {},
    );
  }
  return result;
};

const baseQueryWithReauth: QuickDropBaseQuery = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQueryWithCamelCaseResponse(args, api, extraOptions);

  const url = new URL(result.meta?.request.url ?? '');
  const isProtectedRoute = protectedURLsReauth.includes(url.pathname);
  const isUnauthError = result.error && result.error.status === 401;

  if (!isProtectedRoute && isUnauthError) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const { stayLoggedIn, refreshToken } = (api.getState() as RootState).auth;
        const refreshResult = await baseQuery(
          {
            url: AUTH_API_URLS.REFRESH_AUTH,
            method: REQUEST_METHODS.POST,
            body: { stayLoggedIn },
            headers: { authorization: `Bearer ${refreshToken}` },
          },
          api,
          extraOptions,
        );
        if (refreshResult?.data) {
          const tokens = refreshResult.data as RefreshTokenResponse;
          api.dispatch(setCredentials(tokens));
          result = await baseQueryWithCamelCaseResponse(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQueryWithCamelCaseResponse(args, api, extraOptions);
    }
  }

  return result;
};

const baseQueryWithErrorHandling: QuickDropBaseQuery = async (args, api, extraOptions) => {
  const result = await baseQueryWithReauth(args, api, extraOptions);
  let showErrorMessage = extraOptions?.showErrorMessage ?? true;

  const protectedStatuses: (
    | number
    | 'FETCH_ERROR'
    | 'PARSING_ERROR'
    | 'TIMEOUT_ERROR'
    | 'CUSTOM_ERROR'
  )[] = [401];

  const routeExceptionsOfProtectedStatuses = [
    AUTH_API_URLS.LOGIN,
    AUTH_API_URLS.TWO_FACTOR_AUTH_REQUEST,
  ];

  if (result.error) {
    if ((result.error as ApiVersionResponseError).data?.cause === IP_RESTRICTION_CAUSE) {
      api.dispatch(logout());
      showErrorMessage = false;
    }

    let message: string = i18n.t('errors.somethingWentWrong');
    if (isErrorWithMessage(result.error.data)) {
      message = result.error.data?.message;
    }
    if (result.error.status === 403) {
      message = i18n.t('errors.forbiddenAction');
    }
    if (isErrorWithMessageArray(result.error.data)) {
      message = convertErrorMessagesArrayToString(result.error.data?.message);
    }
    if (result.error.status === 'FETCH_ERROR' || result.error.status === 'TIMEOUT_ERROR') {
      message = i18n.t('errors.cantConnect');
    }

    const url = new URL(result?.meta?.request.url || '');

    if (
      !protectedStatuses.includes(result.error.status) ||
      routeExceptionsOfProtectedStatuses.includes(url.pathname)
    ) {
      if (showErrorMessage) {
        toast.error(message);
      }
    }
  }

  return result;
};

export const quickDropAdminApi = createApi({
  reducerPath: 'quickDropAdminApi',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: () => ({}),
});
