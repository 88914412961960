/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import {
  BagsPurchasedListState,
  BAGS_PURCHASED_LIST_SORT_OPTION_TABLE_KEYS,
} from 'modules/activities/types';
import { ORDER } from 'modules/common';

const initialState: BagsPurchasedListState = {
  page: 1,
  sorting: { sort: BAGS_PURCHASED_LIST_SORT_OPTION_TABLE_KEYS.TIMESTAMP, order: ORDER.DESC },
};

export const bagsPurchasedListSlice = createSlice({
  name: 'bagsPurchasedList',
  initialState,
  reducers: {
    setBagsPurchasedListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setBagsPurchasedListPage: (state, { payload }) => {
      state.page = payload;
    },
    setBagsPurchsedListAccountFilter: (state, { payload }) => {
      state.userUId = payload;
    },
    deleteBagsPurchsedListAccountFilter: (state) => {
      state.userUId = undefined;
    },
  },
});

export const bagsPurchasedListReducer = bagsPurchasedListSlice.reducer;

export const {
  setBagsPurchasedListSorting,
  setBagsPurchasedListPage,
  setBagsPurchsedListAccountFilter,
  deleteBagsPurchsedListAccountFilter,
} = bagsPurchasedListSlice.actions;

export const selectBagsPurchasedList = (state: RootState) => state.bagsPurchasedList;
