import { quickDropAdminApi } from 'config/api';
import { REQUEST_METHODS } from 'modules/common';
import { STATE_PRICES_API_URLS } from 'modules/prices/constants';
import {
  OperatorRate,
  OperatorRateList,
  OperatorRateChange,
  OperatorRateLogList,
  RateLogInfoQueryParams,
} from 'modules/prices/types';

export const operatorRatesApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['OperatorRate', 'OperatorRateLogInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOperatorRates: builder.query<OperatorRate[], void>({
        query: () => ({
          url: STATE_PRICES_API_URLS.OPERATOR_RATES,
          method: REQUEST_METHODS.GET,
        }),
        transformResponse: (data: OperatorRateList) =>
          Object.keys(data)
            .map((key) => data[+key])
            .sort((a, b) => a.itemType.localeCompare(b.itemType)),
        providesTags: (_result, error) => (error ? [] : ['OperatorRate']),
      }),
      updateOperatorRate: builder.mutation<OperatorRate, OperatorRateChange[]>({
        query: (data) => ({
          body: { data },
          url: STATE_PRICES_API_URLS.OPERATOR_RATES,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['OperatorRate', 'OperatorRateLogInfo']),
      }),
      getOperatorRatesLogInfo: builder.query<OperatorRateLogList, RateLogInfoQueryParams>({
        query: ({ page }) => {
          const params = {
            'pagination[page]': page,
            'filter[createdAt][$ne]': null,
          };
          return {
            url: STATE_PRICES_API_URLS.OPERATOR_RATES_HISTORY,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['OperatorRateLogInfo']),
      }),
    }),
  });

export const {
  useGetOperatorRatesQuery,
  useUpdateOperatorRateMutation,
  useLazyGetOperatorRatesLogInfoQuery,
} = operatorRatesApi;
