import { quickDropAdminApi } from 'config/api';
import { REQUEST_METHODS } from 'modules/common';
import { USERS_API_URLS } from 'modules/users/constants';
import {
  AddNewUserBody,
  UserPatchRequest,
  UserDetails,
  UsersList,
  UsersListQueryParams,
  USER_TYPES,
} from 'modules/users/types';

export const usersApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['Users', 'Permissions'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUsers: builder.query<UsersList, UsersListQueryParams>({
        query: ({ page, sort, order, operatorUId, filters }) => {
          const params = {
            'pagination[page]': page,
            'filters[type]': USER_TYPES.ADMIN,
            [`sort[${sort}]`]: order,
            'filters[organizationUId][$in][]': operatorUId,
            ...filters,
          };

          return {
            url: USERS_API_URLS.USER,
            params,
            method: REQUEST_METHODS.GET,
          };
        },

        providesTags: (result) => (result ? ['Users'] : []),
      }),
      addNewUser: builder.mutation<UserDetails, AddNewUserBody>({
        query: (body) => ({
          url: USERS_API_URLS.USER,
          body,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['Users', 'Permissions']),
      }),
      getUserById: builder.query<UserDetails, string>({
        query: (userUId) => ({
          url: `${USERS_API_URLS.USER}/${userUId}`,
          method: REQUEST_METHODS.GET,
        }),
      }),
      updateUserById: builder.mutation<UserDetails, UserPatchRequest>({
        query: ({ userUId, body }) => ({
          url: `${USERS_API_URLS.USER}/${userUId}`,
          body,
          method: REQUEST_METHODS.PATCH,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['Users', 'Permissions']),
        onQueryStarted({ userUId, body: update }, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            usersApi.util.updateQueryData('getUserById', userUId, (results) => {
              const { groupId, groupNameForRTK, organizationUId, organizationNameForRTK, ...rest } =
                update;

              if (groupNameForRTK && groupId) {
                if (organizationUId && organizationNameForRTK) {
                  return {
                    ...results,
                    ...rest,
                    userGroup: { _id: groupId, name: groupNameForRTK },
                    organizations: [{ name: organizationNameForRTK ?? '', _id: organizationUId }],
                  };
                }
                return { ...results, ...rest, userGroup: { _id: groupId, name: groupNameForRTK } };
              }
              if (organizationUId) {
                return {
                  ...results,
                  ...rest,
                  organizations: [{ name: organizationNameForRTK ?? '', _id: organizationUId }],
                };
              }
              return { ...results, ...rest };
            }),
          );
          queryFulfilled.catch(updatedResult.undo);
        },
      }),
    }),
  });

export const {
  useGetUsersQuery,
  useAddNewUserMutation,
  useGetUserByIdQuery,
  useUpdateUserByIdMutation,
} = usersApi;
