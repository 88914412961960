/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { DeviceLogsListState } from 'modules/devices/types';

import { devicesApi } from '../api';

const initialState: DeviceLogsListState = {
  page: 1,
  deviceLogs: [],
};

export const deviceLogsListSlice = createSlice({
  name: 'deviceLogsList',
  initialState,
  reducers: {
    resetDeviceLogs: (state) => {
      state.page = 1;
      state.deviceLogs = [];
    },
    setDeviceLogsListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      devicesApi.endpoints.getDeviceLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.deviceLogs = state.deviceLogs.concat(payload.data);
        } else {
          state.deviceLogs = payload.data;
        }
      },
    );
  },
});

export const deviceLogsListReducer = deviceLogsListSlice.reducer;

export const { setDeviceLogsListPage, resetDeviceLogs } = deviceLogsListSlice.actions;

export const selectDeviceLogsList = (state: RootState) => state.deviceLogsList;
