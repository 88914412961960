import * as yup from 'yup';

import {
  AcceptTransactionRequestFields,
  CREATE_TRANSACTION_REQUEST_FIELDS,
  CreateTransactionRequestFields,
  DeclineTransactionRequestFields,
  EditAccountFields,
} from 'modules/accounts/types';
import { PasswordSchema } from 'modules/auth';
import {
  FirstNameSchema,
  LastNameSchema,
  VALIDATION_ERRORS,
  ZipSchema,
  getErrorMessage,
} from 'modules/common';

export const EditAccountSchema: yup.Schema<EditAccountFields> = yup.object().shape({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  phoneNumber: yup.string(),
  zipCode: ZipSchema,
});

export const CreateTransactionRequestSchema: yup.Schema<CreateTransactionRequestFields> = yup
  .object()
  .shape({
    comment: yup.string(),
    amount: yup
      .number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .min(
        0.01,
        getErrorMessage(
          CREATE_TRANSACTION_REQUEST_FIELDS.AMOUNT,
          VALIDATION_ERRORS.AT_LEAST_ONE_CENT,
        ),
      )
      .test(
        'max-two-decimal-places',
        getErrorMessage(
          CREATE_TRANSACTION_REQUEST_FIELDS.AMOUNT,
          VALIDATION_ERRORS.MAX_TWO_DECIMAL_PLACES,
        ) || '',
        (number) => {
          if (number !== null && number !== undefined) {
            const parts = number.toString().split('.');
            return parts.length < 2 || parts[1].length <= 2;
          }
          return true;
        },
      )
      .required(
        getErrorMessage(CREATE_TRANSACTION_REQUEST_FIELDS.AMOUNT, VALIDATION_ERRORS.NUMBER_FIELD),
      ),
  });

export const DeclineTransactionRequestSchema: yup.Schema<DeclineTransactionRequestFields> = yup
  .object()
  .shape({
    revisionComment: yup.string(),
  });

export const AcceptTransactionRequestSchema: yup.Schema<AcceptTransactionRequestFields> = yup
  .object()
  .shape({
    revisionComment: yup.string(),
    password: PasswordSchema,
  });
