/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';

import { operatorRatesApi, stateRatesApi } from '../api';
import { OperatorAndStateRatesLogsState, Rate, RateLogInfo } from '../types';

const initialState: OperatorAndStateRatesLogsState = {
  operatorRateLogs: {
    page: 1,
    lastPage: 1,
    logItems: [],
  },
  stateRateLogs: {
    page: 1,
    lastPage: 1,
    logItems: [],
  },
};

type RateHistoryPayload<TRate extends Rate> = {
  data: (TRate & RateLogInfo)[];
  meta: {
    page: number;
    lastPage: number;
  };
};

type RateHistorySubState<TRate extends Rate> = {
  page: number;
  lastPage: number;
  logItems: (TRate & RateLogInfo)[];
};

const historyPageReducer = <TRate extends Rate>(
  state: RateHistorySubState<TRate>,
  payload: RateHistoryPayload<TRate>,
) => {
  if (!payload.data.length) {
    return;
  }
  if (payload.meta.page > 1) {
    state.lastPage = payload.meta.lastPage;
    state.logItems = state.logItems.concat(payload.data);
  } else {
    state.lastPage = payload.meta.lastPage;
    state.logItems = payload.data;
  }
};

export const operatorAndStateRatesLogsSlice = createSlice({
  name: 'operatorAndStateRatesLogs',
  initialState,
  reducers: {
    setStateRatesLogPage: (state, { payload }: { payload: number }) => {
      state.stateRateLogs.page = payload;
    },
    setOperatorRatesLogPage: (state, { payload }: { payload: number }) => {
      state.operatorRateLogs.page = payload;
    },
    resetStateRatesLogs: (state) => {
      state.stateRateLogs.page = 1;
      state.stateRateLogs.logItems = [];
    },
    resetOperatorRatesLogs: (state) => {
      state.operatorRateLogs.logItems = [];
      state.operatorRateLogs.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      operatorRatesApi.endpoints.getOperatorRatesLogInfo.matchFulfilled,
      (state, { payload }) => {
        historyPageReducer(state.operatorRateLogs, payload);
      },
    );

    builder.addMatcher(
      stateRatesApi.endpoints.getStateRatesLogInfo.matchFulfilled,
      (state, { payload }) => {
        historyPageReducer(state.stateRateLogs, payload);
      },
    );
  },
});

export const operatorAndStateRatesLogsReducer = operatorAndStateRatesLogsSlice.reducer;

export const {
  resetOperatorRatesLogs,
  resetStateRatesLogs,
  setOperatorRatesLogPage,
  setStateRatesLogPage,
} = operatorAndStateRatesLogsSlice.actions;

export const selectOperatorRatesLogs = (state: RootState) =>
  state.operatorAndStateRatesLogs.operatorRateLogs.logItems;
export const selectStateRatesLogs = (state: RootState) =>
  state.operatorAndStateRatesLogs.stateRateLogs.logItems;

export const selectOperatorRatesLogsPage = (state: RootState) =>
  state.operatorAndStateRatesLogs.operatorRateLogs.page;
export const selectStateRatesLogsPage = (state: RootState) =>
  state.operatorAndStateRatesLogs.stateRateLogs.page;

export const selectOperatorRatesLogsLastPage = (state: RootState) =>
  state.operatorAndStateRatesLogs.operatorRateLogs.lastPage;
export const selectStateRatesLogsLastPage = (state: RootState) =>
  state.operatorAndStateRatesLogs.stateRateLogs.lastPage;
