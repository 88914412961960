import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const ipAddressesModule = () => import('modules/ip-addresses');

export const ipAddressesRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.IP_ADDRESSES,
        lazy: lazyImportComponent(ipAddressesModule, 'IpAddressesListScreen'),
      },
    ],
  },
  {
    path: PATHS.NOT_ALLOWED_IP_ADDRESS,
    lazy: lazyImportComponent(ipAddressesModule, 'NotAllowedIpAddressScreen'),
  },
];
