import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const pricesModule = () => import('modules/prices');

export const pricesRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      { path: PATHS.STATE_RATES, lazy: lazyImportComponent(pricesModule, 'StateRates') },
      { path: PATHS.OPERATOR_RATES, lazy: lazyImportComponent(pricesModule, 'OperatorRates') },
    ],
  },
];
