import { quickDropAdminApi } from 'config/api';
import { REQUEST_METHODS } from 'modules/common';
import { SUPPORT_CONTACT_API_URLS } from 'modules/support-contact/constants';
import { resetSupportContactLogs, setSupportContactLogsPage } from 'modules/support-contact/store';
import {
  SupportContact,
  SupportContactLogList,
  SupportContactLogInfoQueryParams,
  SupportContactForm,
} from 'modules/support-contact/types';

export const supportContactApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['SupportContactLogInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCurrentSupportContact: builder.query<SupportContact, void>({
        query: () => ({
          url: SUPPORT_CONTACT_API_URLS.GET_LATEST,
          method: REQUEST_METHODS.GET,
        }),
        extraOptions: { disableDefaultSuccessToaster: true },
      }),
      addNewSupportContact: builder.mutation<SupportContact, SupportContactForm>({
        query: (body) => ({
          body,
          url: SUPPORT_CONTACT_API_URLS.BASE_URL,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['SupportContactLogInfo']),
        onQueryStarted(newSupportContact, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            supportContactApi.util.updateQueryData('getCurrentSupportContact', undefined, () => {
              return { ...newSupportContact, createdAt: new Date().toISOString() };
            }),
          );
          queryFulfilled
            .then(() => {
              dispatch(setSupportContactLogsPage(1));
              dispatch(resetSupportContactLogs());
            })
            .catch(updatedResult.undo);
        },
      }),
      getSupportContactLogInfo: builder.query<
        SupportContactLogList,
        SupportContactLogInfoQueryParams
      >({
        query: ({ page }) => {
          const params = {
            'pagination[page]': page,
          };
          return {
            url: SUPPORT_CONTACT_API_URLS.BASE_URL,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['SupportContactLogInfo']),
      }),
    }),
  });

export const {
  useGetCurrentSupportContactQuery,
  useAddNewSupportContactMutation,
  useLazyGetSupportContactLogInfoQuery,
} = supportContactApi;
