/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { LocationLogsListState } from 'modules/locations/types';

import { locationsApi } from '../api';

const initialState: LocationLogsListState = {
  page: 1,
  locationLogs: [],
};

export const locationLogsListSlice = createSlice({
  name: 'locationLogsList',
  initialState,
  reducers: {
    resetLocationLogs: (state) => {
      state.page = 1;
      state.locationLogs = [];
    },
    setLocationLogsListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      locationsApi.endpoints.getLocationLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.locationLogs = state.locationLogs.concat(payload.data);
        } else {
          state.locationLogs = payload.data;
        }
      },
    );
  },
});

export const LocationLogsListReducer = locationLogsListSlice.reducer;

export const { setLocationLogsListPage, resetLocationLogs } = locationLogsListSlice.actions;

export const selectLocationLogsList = (state: RootState) => state.locationLogsList;
