/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ORDER } from 'modules/common';
import { LOCATION_SORT_OPTION_TABLE_KEYS, LocationsListState } from 'modules/locations/types';

const initialState: LocationsListState = {
  page: 1,
  sorting: { sort: LOCATION_SORT_OPTION_TABLE_KEYS.IN_SERVICE, order: ORDER.DESC },
};

export const locationsListSlice = createSlice({
  name: 'locationsList',
  initialState,
  reducers: {
    setLocationsListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setLocationsListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const locationsListReducer = locationsListSlice.reducer;

export const { setLocationsListSorting, setLocationsListPage } = locationsListSlice.actions;

export const selectLocationsList = (state: RootState) => state.locationsList;
