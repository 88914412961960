import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const dashboardModule = () => import('modules/dashboard/screens');

export const dashboardRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [{ path: PATHS.DASHBOARD, lazy: lazyImportComponent(dashboardModule, 'Dashboard') }],
  },
];
