import { quickDropAdminApi } from 'config/api';
import {
  AccountWalletDetailsListQueryParams,
  AccountsListQueryParams,
  FinancialTransactionDetailsListQueryParams,
} from 'modules/accounts';
import {
  BagsDepositedListQueryParams,
  BagsPurchasedListQueryParams,
  DropOffSessionsByOperatorUIdListQueryParams,
  MoneyTransfersByOperatorUIdListQueryParams,
} from 'modules/activities';
import { ORDER, REGEX_OPTIONS, REQUEST_METHODS } from 'modules/common/types';
import { DevicesListQueryParams } from 'modules/devices/types';
import { EXPORT_API_URLS, ExportResponse } from 'modules/export';
import { LocationsListQueryParams } from 'modules/locations';
import { OperatorsListQueryParams } from 'modules/operators';
import {
  DailyActivitiesSummaryReportsQueryParams,
  DailySummaryReportsQueryParams,
} from 'modules/reports/types';
import { UsersListQueryParams } from 'modules/users';
import { USER_TYPES } from 'modules/users/types';

export const exportApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['Export'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      exportDevices: builder.query<ExportResponse, DevicesListQueryParams>({
        query: ({ sort, order, status, operatorUId, deviceIdSearch }) => {
          const params = {
            [`sort[${sort}]`]: order,
            'filters[status][$in][]': status,
            'filters[organizationUId][$in][]': operatorUId,
            'filters[deviceId][$regex]': deviceIdSearch ?? undefined,
            'filters[deviceId][$options]': deviceIdSearch
              ? REGEX_OPTIONS.CASE_INSENSITIVE
              : undefined,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_DEVICES,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportDropOffs: builder.query<ExportResponse, LocationsListQueryParams>({
        query: ({ sort, order, status, search, organizationUId }) => {
          const params = {
            [`sort[${sort}]`]: order,
            'filters[status][$in][]': status,
            'filters[organizationUId][$in][]': organizationUId,
            'filters[search]': search,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_DROP_OFFS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportUsers: builder.query<ExportResponse, UsersListQueryParams>({
        query: ({ sort, order, operatorUId, filters }) => {
          const params = {
            [`sort[${sort}]`]: order,
            'filters[type]': USER_TYPES.ADMIN,
            'filters[organizationUId][$in][]': operatorUId,
            ...filters,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_USERS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportTransactions: builder.query<ExportResponse, FinancialTransactionDetailsListQueryParams>(
        {
          query: ({ userUId, sort, order, filters }) => {
            const params = {
              'filters[userUId][equals]': userUId,
              [`sort[${sort}]`]: order,
              ...filters,
            };
            return {
              url: EXPORT_API_URLS.TRANSACTIONS,
              params,
              method: REQUEST_METHODS.GET,
            };
          },
        },
      ),
      exportWalletDetails: builder.query<ExportResponse, AccountWalletDetailsListQueryParams>({
        query: ({ userUId, sort, order, filters }) => {
          const params = {
            'filters[userUId][equals]': userUId,
            [`sort[${sort}]`]: order,
            ...filters,
          };
          return {
            url: EXPORT_API_URLS.ACCOUNT_STATEMENTS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportAccounts: builder.query<ExportResponse, AccountsListQueryParams>({
        query: ({ sort, order, filters, status }) => {
          const params = {
            'filters[type]': USER_TYPES.CUSTOMER,
            'filters[status]': status,
            [`sort[${sort}]`]: order,
            ...filters,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_USERS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportOperators: builder.query<ExportResponse, OperatorsListQueryParams>({
        query: ({ sort, order, status }) => {
          const params = {
            'filters[status][$in][]': status,
            [`sort[${sort}]`]: order,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_OPERATORS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportBagsPurchased: builder.query<ExportResponse, BagsPurchasedListQueryParams>({
        query: ({ operatorUId, order = ORDER.ASC, sort, deviceId, userUId }) => {
          const params = {
            'filters[organizationUId][in][]': operatorUId,
            'filters[deviceId][in][]': deviceId,
            'filters[userUId][in][]': userUId,
            [`sort[${sort}]`]: order,
          };

          return {
            url: EXPORT_API_URLS.EXPORT_BAG_PURCHASES,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportDropOffSessions: builder.query<
        ExportResponse,
        DropOffSessionsByOperatorUIdListQueryParams
      >({
        query: ({ operatorUId, order = ORDER.ASC, sort, deviceId }) => {
          const params = {
            'filters[organizationUId][$in][]': operatorUId,
            [`sort[${sort}]`]: order,
          };
          if (deviceId) {
            params['filters[deviceId][$in][]'] = deviceId;
          }

          return {
            url: EXPORT_API_URLS.EXPORT_DROP_OFF_SESSIONS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportMoneyTransfers: builder.query<
        ExportResponse,
        MoneyTransfersByOperatorUIdListQueryParams
      >({
        query: ({ operatorUId, order = ORDER.ASC, sort, filters }) => {
          const params = {
            ...filters,
            'filters[organizationUId][in][]': operatorUId,
            [`sort[${sort}]`]: order,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_MONEY_TRANSFERS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportBagDeposited: builder.query<ExportResponse, BagsDepositedListQueryParams>({
        query: ({ sort, order, filters, operatorUId }) => {
          const params = {
            ...filters,
            'filters[organizationUId][$in][]': operatorUId,
            [`sort[${sort}]`]: order,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_DROPPED_BAGS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportDailySummaries: builder.query<ExportResponse, DailySummaryReportsQueryParams>({
        query: ({ sort, order, certificate, type, organizationUId, from, until }) => {
          const params = {
            [`sort[${sort}]`]: order,
            'filters[organizationUId][$in][]': organizationUId,
            'filters[certificateUId][$in][]': certificate,
            'filters[type][$in][]': type,
            'filters[createdAt][$gt]': from,
            'filters[createdAt][$lt]': until,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_DAILY_SUMMARIES,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      exportDailyActivitiesSummary: builder.query<
        ExportResponse,
        DailyActivitiesSummaryReportsQueryParams
      >({
        query: ({ organizationUId, from, until }) => {
          const params = {
            'filters[organizationUId][$in][]': organizationUId,
            'filters[createdAt][$gt]': from,
            'filters[createdAt][$lt]': until,
          };
          return {
            url: EXPORT_API_URLS.EXPORT_DAILY_ACTIVITIES_SUMMARY,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
    }),
  });

export const {
  useLazyExportDevicesQuery,
  useLazyExportDropOffsQuery,
  useLazyExportUsersQuery,
  useLazyExportAccountsQuery,
  useLazyExportOperatorsQuery,
  useLazyExportWalletDetailsQuery,
  useLazyExportTransactionsQuery,
  useLazyExportBagsPurchasedQuery,
  useLazyExportDropOffSessionsQuery,
  useLazyExportMoneyTransfersQuery,
  useLazyExportBagDepositedQuery,
  useLazyExportDailySummariesQuery,
  useLazyExportDailyActivitiesSummaryQuery,
} = exportApi;
