/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { AccountLogsListState } from 'modules/accounts/types';

import { accountsApi } from '../api';

const initialState: AccountLogsListState = {
  page: 1,
  accountLogs: [],
};

export const accountLogsListSlice = createSlice({
  name: 'accountLogsList',
  initialState,
  reducers: {
    resetAccountLogs: (state) => {
      state.page = 1;
      state.accountLogs = [];
    },
    setAccountLogsListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      accountsApi.endpoints.getUserLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.accountLogs = state.accountLogs.concat(payload.data);
        } else {
          state.accountLogs = payload.data;
        }
      },
    );
  },
});

export const accountLogsListReducer = accountLogsListSlice.reducer;

export const { setAccountLogsListPage, resetAccountLogs } = accountLogsListSlice.actions;

export const selectAccountLogsList = (state: RootState) => state.accountLogsList;
