import { quickDropAdminApi } from 'config/api';
import { CERTIFICATES_API_URLS } from 'modules/certificates';
import {
  CERTIFICATE_STATUSES,
  Certificate,
  CertificatePatchRequest,
  CertificatesList,
  CertificatesListQueryParams,
} from 'modules/certificates/types';
import { REGEX_OPTIONS, REQUEST_METHODS } from 'modules/common';

export const certificatesApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['Certificates', 'Devices'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCertificates: builder.query<CertificatesList, CertificatesListQueryParams>({
        query: ({ page, take, status, search, sort, order }) => {
          const params = {
            'pagination[page]': page,
            'pagination[take]': take,
            'filters[status][$in][]': status,
            'filters[certificateUId][$regex]': search,
            'filters[certificateUId][$options]': search
              ? REGEX_OPTIONS.CASE_INSENSITIVE
              : undefined,
            [`sort[${sort}]`]: order,
          };

          return {
            url: CERTIFICATES_API_URLS.CERTIFICATE,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (!result ? [] : ['Certificates']),
      }),
      addNewCertificate: builder.mutation<Certificate, string>({
        query: (certificateUId) => ({
          url: CERTIFICATES_API_URLS.CERTIFICATE,
          body: { certificateUId, status: CERTIFICATE_STATUSES.ACTIVE },
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['Certificates']),
      }),
      updateCertificate: builder.mutation<boolean, CertificatePatchRequest>({
        query: ({ certificateUId, body }) => ({
          url: CERTIFICATES_API_URLS.CERTIFICATE_BY_ID(certificateUId),
          body,
          method: REQUEST_METHODS.PATCH,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['Certificates', 'Devices']),
      }),
    }),
  });

export const {
  useGetCertificatesQuery,
  useAddNewCertificateMutation,
  useUpdateCertificateMutation,
} = certificatesApi;
