/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { CertificateListState } from 'modules/certificates/types';

const initialState: CertificateListState = {
  page: 1,
};

export const certificatesList = createSlice({
  name: 'certificatesList',
  initialState,
  reducers: {
    setCertificatesListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const certificatesListReducer = certificatesList.reducer;

export const { setCertificatesListPage } = certificatesList.actions;

export const selectCertificatesList = (state: RootState) => state.certificatesList;
