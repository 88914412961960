import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const notificationsModule = () => import('modules/notifications');

export const notificationsRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.SEND_NOTIFICATIONS,
        lazy: lazyImportComponent(notificationsModule, 'SendNotificationScreen'),
      },

      {
        path: PATHS.SEND_NOTIFICATIONS_HISTORY,
        lazy: lazyImportComponent(notificationsModule, 'CustomNotificationsListScreen'),
      },
    ],
  },
];
