import fileDownload from 'js-file-download';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getAccessToken } from 'modules/auth';
import { ExportPopup, useAppDispatch, useAppSelector } from 'modules/common';
import {
  addEventError,
  getExportEventQueue,
  getExportEvents,
  removeFromExportEvent,
  removeFromExportEventQueue,
  setExportEventLoading,
} from 'modules/export/store';
import { EXPORT_STATUS } from 'modules/export/types';

export const ExportContainer: FC = () => {
  const { t } = useTranslation();
  const URL = import.meta.env.VITE_APP_API_URL;
  const dispatch = useAppDispatch();
  const data = useAppSelector(getExportEventQueue);
  const accessToken = useAppSelector(getAccessToken);
  const completedEvents = useAppSelector(getExportEvents);

  const getCompletedExport = (id: string) =>
    completedEvents.find(({ exportId }) => exportId === id);

  const removePopup = (id: string) => {
    dispatch(removeFromExportEventQueue(id));
    dispatch(removeFromExportEvent(id));
  };

  const handleDownload = async (id: string) => {
    const completedExport = getCompletedExport(id);
    const queuedExport = data.find(({ id: queueId }) => queueId === id);

    if (completedExport && queuedExport) {
      const { downloadLink } = completedExport;
      const { fileName } = queuedExport;

      dispatch(setExportEventLoading(id));

      try {
        // We are using FETCH api here, because RTK Query should NOT used with Blobs and file downloads.
        // https://stackoverflow.com/questions/75536269/using-rtk-query-what-is-the-best-practice-to-download-a-file
        const res = await fetch(`${URL}${downloadLink}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/vnd.ms-excel',
          },
        });
        const blob = await res.blob();
        fileDownload(blob, fileName);
        dispatch(removeFromExportEventQueue(id));
        dispatch(removeFromExportEvent(id));
      } catch (error) {
        dispatch(addEventError({ message: t('export.exportError'), id }));
      }
    }
  };

  return (
    <div className="w-[400px]  h-fit fixed z-50 right-0 bottom-[90px]">
      {data.length
        ? data.map(({ isLoading, fileName, id }) => {
            const completedExport = getCompletedExport(id);
            const isFailed = completedExport?.newStatus === EXPORT_STATUS.FAILED;

            return (
              <ExportPopup
                key={id}
                isLoading={isLoading}
                fileName={fileName}
                onClose={() => removePopup(id)}
                onDownload={() => handleDownload(id)}
                isFailed={isFailed}
              />
            );
          })
        : null}
    </div>
  );
};
