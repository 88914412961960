/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { authApi, AuthState, Tokens, WhoAmIResponse } from 'modules/auth';
import { USER_TYPES } from 'modules/users/types';

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  refreshTokenExpiresAtInMs: null,
  isLoggedIn: false,
  stayLoggedIn: false,
  roles: [],
  firstName: '',
  lastName: '',
  userUId: '',
  operators: [],
  selectedOperator: null,
  userType: null,
};

const handleSetTokens = (state: AuthState, { payload }: PayloadAction<Tokens>) => {
  state.accessToken = payload.accessToken;
  state.refreshToken = payload.refreshToken;
  state.refreshTokenExpiresAtInMs = new Date().getTime() + payload.refreshTokenExpiresInMs;
};

const handleSetUser = (state: AuthState, user: WhoAmIResponse) => {
  state.userType = user.type;
  state.roles = user.group?.roles ?? [];

  state.operators = user.organizations ?? [];

  if (state.operators.length === 1) {
    [state.selectedOperator] = state.operators;
  }

  state.firstName = user.firstName || '';
  state.lastName = user.lastName || '';
  state.userUId = user.userUId;
};

const handleInitState = () => {
  return initialState;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      handleSetTokens(state, action);
    },
    logout: handleInitState,
    login: (state) => {
      state.isLoggedIn = true;
    },
    setOperator: (state, { payload }) => {
      state.selectedOperator = payload;
    },
    setDeviceId: (state, { payload }) => {
      state.selectedDeviceId = payload;
    },
    setStayLoggedIn: (state, { payload }) => {
      state.stayLoggedIn = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, handleInitState);
    builder.addMatcher(authApi.endpoints.refreshTokensManually.matchFulfilled, handleSetTokens);
    builder.addMatcher(authApi.endpoints.whoAmI.matchFulfilled, (state, { payload }) => {
      handleSetUser(state, payload);
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCredentials, logout, login, setOperator, setDeviceId, setStayLoggedIn } =
  authSlice.actions;

export const authReducer = authSlice.reducer;

export const getAccessToken = (state: RootState) => state.auth.accessToken;
export const getRefreshToken = (state: RootState) => state.auth.refreshToken;
export const getIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const getRefreshTokenExpiresAtInMs = (state: RootState) =>
  state.auth.refreshTokenExpiresAtInMs;
export const getRoles = (state: RootState) => state.auth.roles;
export const getName = (state: RootState) => ({
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
});
export const selectStayLoggedIn = (state: RootState) => state.auth.stayLoggedIn;
export const selectUserType = (state: RootState) => state.auth.userType;
export const selectIsSuperuser = (state: RootState) => state.auth.userType === USER_TYPES.SUPERUSER;
export const selectOperators = (state: RootState) => state.auth.operators;
export const selectSelectedOperator = (state: RootState) => state.auth.selectedOperator;
export const selectSelectedDeviceId = (state: RootState) => state.auth.selectedDeviceId;
export const selectUserUId = (state: RootState) => state.auth.userUId;
