import * as yup from 'yup';

export enum VALIDATION_ERRORS {
  REQUIRED_FIELD = 'requiredField',
  INVALID_EMAIL = 'invalidEmail',
  NUMBER_FIELD = 'numberField',
  MIN_6_MAX_12 = 'min6Max12',
  LAT_MIN_MAX = 'latMinMax',
  LON_MIN_MAX = 'lonMinMax',
  ACTIVE_DEVICE_NEEDS_CERTIFICATE = 'activeDeviceNeedsCertificate',
  ABOVE_MAX_CHARACTERS = 'aboveMaxCharacters',
  MUST_BE_VALID_DATE_FROM_YESTERDAY = 'mustBeValidDateFromYesterday',
  CLOSED_24H_FLAGS_EXCLUSIVE = 'closed24HFlagsExclusive',
  CLOSING_MUST_BE_AFTER_OPENING = 'closingMustBeAfterOpening',
  PHONE_NUMBER = 'phoneNumber',
  AT_LEAST_ONE_CENT = 'atLeastOneCent',
  MAX_TWO_DECIMAL_PLACES = 'maxTwoDecimalPlaces',
}

export const getErrorMessage = (
  fieldName: string,
  errorKey: VALIDATION_ERRORS,
  // eslint-disable-next-line @typescript-eslint/ban-types
): yup.Message<{}> | undefined => {
  return { fieldName, error: `validation.${errorKey}` };
};

export const EmailSchema: yup.Schema<string> = yup
  .string()
  .email(getErrorMessage('email', VALIDATION_ERRORS.INVALID_EMAIL))
  .required(getErrorMessage('email', VALIDATION_ERRORS.REQUIRED_FIELD));

export const CheckBoxSchema: yup.Schema<boolean> = yup.boolean().required();

export const StringSchemaGenerator = (fieldName: string): yup.Schema<string> =>
  yup.string().required(getErrorMessage(fieldName, VALIDATION_ERRORS.REQUIRED_FIELD));

export const FirstNameSchema = StringSchemaGenerator('firstName');
export const LastNameSchema = StringSchemaGenerator('lastName');
export const ZipSchema = StringSchemaGenerator('zip');
export const PhoneNumberSchema = StringSchemaGenerator('phoneNumber');
export const UserTypeSchema = StringSchemaGenerator('type');
export const NameSchema = StringSchemaGenerator('name');
export const DescriptionSchema = yup.string().nonNullable().defined();
export const CertificateUIdSchema = StringSchemaGenerator('certificateUId');
export const IpAddressSchema = StringSchemaGenerator('ipAddress');
