import { quickDropAdminApi } from 'config/api';

import { API_VERSIONS_API_URLS } from '../constants';
import { ApiVersionResponse } from '../types';

export const apiVersionApi = quickDropAdminApi.injectEndpoints({
  endpoints: (builder) => ({
    apiVersion: builder.query<ApiVersionResponse, void>({
      query: () => ({
        url: API_VERSIONS_API_URLS.VERSION,
      }),
    }),
  }),
});

export const { useApiVersionQuery } = apiVersionApi;
