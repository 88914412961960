/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import {
  DATE_RANGE_TYPES,
  ORDER,
  calculateDateBack,
  emptySearchAndFilters,
  now,
} from 'modules/common';
import {
  CUSTOM_NOTIFICATIONS_LIST_SORT_OPTIONS,
  CustomNotificationsListState,
} from 'modules/notifications/types';

const initDateFilter = () => ({
  dateRangeType: DATE_RANGE_TYPES.LAST_7_DAYS,
  dateRange: {
    startDate: calculateDateBack(7).toISOString(),
    endDate: now().toISOString(),
  },
});

const initialState: CustomNotificationsListState = {
  page: 1,
  sorting: { sort: CUSTOM_NOTIFICATIONS_LIST_SORT_OPTIONS.CREATED_AT, order: ORDER.DESC },
  searchAndFilters: {
    ...emptySearchAndFilters,
    addFilter: {
      ...emptySearchAndFilters.addFilter,
      ...initDateFilter(),
    },
  },
};

export const customNotificationsList = createSlice({
  name: 'customNotificationsList',
  initialState,
  reducers: {
    setCustomNotificationsPage: (state, { payload }) => {
      state.page = payload;
    },
    setCustomNotificationsSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setCustomNotificationsSearchAndFilters: (state, { payload }) => {
      state.searchAndFilters = payload;
    },
    initCustomNotificationsDateFilter: (state) => {
      state.searchAndFilters.addFilter = {
        ...state.searchAndFilters.addFilter,
        ...initDateFilter(),
      };
    },
  },
});

export const customNotificationsListReducer = customNotificationsList.reducer;

export const {
  setCustomNotificationsPage,
  setCustomNotificationsSorting,
  setCustomNotificationsSearchAndFilters,
  initCustomNotificationsDateFilter,
} = customNotificationsList.actions;

export const selectCustomNotifications = (state: RootState) => state.customNotificationsList;
