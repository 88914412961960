import * as Yup from 'yup';

export const StateRateTableSchema = Yup.array().of(
  Yup.object().shape({
    itemType: Yup.string().required(),
    price: Yup.number().min(0).required(),
    limit: Yup.number().min(0).required(),
    fiveCentExchangeRate: Yup.number().min(0).required(),
  }),
);

export const OperatorRatesTableSchema = Yup.array().of(
  Yup.object().shape({
    itemType: Yup.string().required(),
    SRP: Yup.number().min(0).required(),
    calculationCRV: Yup.number().min(0).min(0).required(),
  }),
);
