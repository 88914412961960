import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const accountsModule = () => import('modules/accounts');

export const accountsRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.ACCOUNTS_BY_STATUS,
        lazy: lazyImportComponent(accountsModule, 'Accounts'),
      },
      {
        path: PATHS.ACCOUNT_BY_ID,
        lazy: lazyImportComponent(accountsModule, 'AccountById'),
      },

      {
        path: PATHS.WALLET_DETAILS,
        lazy: lazyImportComponent(accountsModule, 'WalletDetails'),
      },

      {
        path: PATHS.FINANCIAL_TRANSACTIONS,
        lazy: lazyImportComponent(accountsModule, 'FinancialTransactions'),
      },
    ],
  },
];
