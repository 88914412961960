import { quickDropAdminApi } from 'config/api';
import { ACCOUNTS_API_URLS } from 'modules/accounts/constants';
import { resetAccountLogs, setAccountLogsListPage } from 'modules/accounts/store';
import {
  AccountsList,
  AccountsListQueryParams,
  AccountDetails,
  AccountPatchRequest,
  BagQualityByUser,
  WalletSummaryByUser,
  BagTransactionHistoryByUser,
  TransferHistoryByUser,
  BagDispenseHistoryByUser,
  AccountWalletDetailsList,
  AccountWalletDetailsListQueryParams,
  UserLogInfoList,
  AccountLogInfo,
  FinancialTransactionList,
  FinancialTransactionDetailsListQueryParams,
  TransactionRequestList,
  TransactionRequestsOnAccountQueryParams,
  AddNewTransactionRequest,
  TransactionRequest,
  AcceptTransactionRequest,
  RevisionTransactionRequest,
  DailyLimitRemaining,
} from 'modules/accounts/types';
import { CommentPostRequest, LogInfoQueryParams, ORDER, REQUEST_METHODS } from 'modules/common';
import { USERS_API_URLS } from 'modules/users';
import { USER_TYPES } from 'modules/users/types';

export const accountsApi = quickDropAdminApi
  .enhanceEndpoints({
    addTagTypes: [
      'Account',
      'AccountWalletDetails',
      'UserLogInfo',
      'AccountDetails',
      'AccountWalletSummaryByUser',
      'TransactionRequests',
      'DailyLimitRemaining',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAccounts: builder.query<AccountsList, AccountsListQueryParams>({
        query: ({ page, sort, order, status, filters, take }) => {
          const params = {
            'pagination[take]': take,
            'pagination[page]': page,
            'filters[type]': USER_TYPES.CUSTOMER,
            'filters[status]': status,
            [`sort[${sort}]`]: order,
            ...filters,
          };
          return {
            url: USERS_API_URLS.USER,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['Account']),
      }),
      getAccountById: builder.query<AccountDetails, string>({
        query: (userUId) => ({
          url: `${USERS_API_URLS.USER}/${userUId}`,
          method: REQUEST_METHODS.GET,
        }),
        providesTags: ['AccountDetails'],
      }),
      getBagQualityByUser: builder.query<BagQualityByUser, string>({
        query: (userUId) => ({
          url: ACCOUNTS_API_URLS.BAG_QUALITY_BY_USER(userUId),
          method: REQUEST_METHODS.GET,
        }),
        transformResponse: (data: BagQualityByUser[]) => data[0],
      }),
      getBagDispenseHistoryByUser: builder.query<BagDispenseHistoryByUser, string>({
        query: (userUId) => ({
          url: ACCOUNTS_API_URLS.BAG_DISPENSE_HISTORY_BY_USER(userUId),
          method: REQUEST_METHODS.GET,
        }),
      }),
      getBagTransactionHistoryByUser: builder.query<BagTransactionHistoryByUser, string>({
        query: (userUId) => ({
          url: ACCOUNTS_API_URLS.TRANSACTION_HISTORY_BY_USER(userUId),
          method: REQUEST_METHODS.GET,
        }),
      }),
      getTransferHistoryByUser: builder.query<TransferHistoryByUser, string>({
        query: (userUId) => ({
          url: ACCOUNTS_API_URLS.TRANSFER_HISTORY_BY_USER(userUId),
          method: REQUEST_METHODS.GET,
        }),
      }),
      getFinancialTransactions: builder.query<
        FinancialTransactionList,
        FinancialTransactionDetailsListQueryParams
      >({
        query: ({ userUId, page, take, sort, order, filters }) => {
          const params = {
            'pagination[page]': page,
            'pagination[take]': take,
            'filters[userUId][equals]': userUId,
            [`sort[${sort}]`]: order,
            ...filters,
          };
          return {
            url: ACCOUNTS_API_URLS.FINANCIAL_TRANSACTIONS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      getWalletSummaryByUser: builder.query<WalletSummaryByUser, string>({
        query: (userUId) => ({
          url: ACCOUNTS_API_URLS.FINANCIAL_ACCOUNT(userUId),
          method: REQUEST_METHODS.GET,
        }),
        providesTags: (_result, error) => (error ? [] : ['AccountWalletSummaryByUser']),
      }),
      getWalletDetailsList: builder.query<
        AccountWalletDetailsList,
        AccountWalletDetailsListQueryParams
      >({
        query: ({ userUId, page, take, sort, order, filters }) => {
          const params = {
            'pagination[page]': page,
            'pagination[take]': take,
            'filters[userUId][equals]': userUId,
            [`sort[${sort}]`]: order,
            ...filters,
          };
          return {
            url: ACCOUNTS_API_URLS.ACCOUNT_STATEMENTS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      getWalletDetailsByUser: builder.query<
        AccountWalletDetailsList,
        AccountWalletDetailsListQueryParams
      >({
        query: ({ userUId, page, sort, order }) => {
          const params = {
            'pagination[page]': page,
            [`sort[${sort}]`]: order,
          };
          return {
            url: ACCOUNTS_API_URLS.FINANCIAL_ACCOUNT(userUId),
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['AccountWalletDetails']),
      }),
      updateAccountById: builder.mutation<AccountDetails, AccountPatchRequest>({
        query: ({ userUId, body }) => ({
          url: `${USERS_API_URLS.USER}/${userUId}`,
          body,
          method: REQUEST_METHODS.PATCH,
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : ['Account', 'UserLogInfo', 'AccountDetails'],
        onQueryStarted({ userUId, body: update }, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            accountsApi.util.updateQueryData('getAccountById', userUId, (results) => {
              return { ...results, ...update };
            }),
          );

          queryFulfilled
            .then(() => {
              dispatch(setAccountLogsListPage(1));
              dispatch(resetAccountLogs());
            })
            .catch(updatedResult.undo);
        },
      }),
      getUserLogInfo: builder.query<UserLogInfoList, LogInfoQueryParams>({
        query: ({ page, id }) => {
          const params = {
            'pagination[page]': page,
            'filters[userUId]': id,
          };
          return {
            url: USERS_API_URLS.USER_LOG_INFO,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['UserLogInfo']),
      }),
      addUserComment: builder.mutation<AccountLogInfo, CommentPostRequest>({
        query: ({ id, comment }) => ({
          url: USERS_API_URLS.USER_LOG_INFO,
          body: { userUId: id, comment },
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['UserLogInfo']),
      }),
      getTransactionRequestsOnAccount: builder.query<
        TransactionRequestList,
        TransactionRequestsOnAccountQueryParams
      >({
        query: ({ page, userUId }) => {
          const params = {
            'pagination[page]': page,
            'sort[createdAt]': ORDER.DESC,
            'filters[userUId][equals]': userUId,
          };
          return {
            url: ACCOUNTS_API_URLS.TRANSACTION_REQUESTS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['TransactionRequests']),
      }),
      createTransactionRequest: builder.mutation<TransactionRequest, AddNewTransactionRequest>({
        query: (body) => ({
          url: ACCOUNTS_API_URLS.TRANSACTION_REQUESTS,
          body,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: ['UserLogInfo', 'TransactionRequests', 'DailyLimitRemaining', 'Account'],
      }),
      acceptTransactionRequest: builder.mutation<TransactionRequest, AcceptTransactionRequest>({
        query: ({ id, body }) => ({
          url: ACCOUNTS_API_URLS.ACCEPT_TRANSACTION_REQUEST(id),
          method: REQUEST_METHODS.PATCH,
          body,
        }),
        invalidatesTags: [
          'UserLogInfo',
          'AccountWalletSummaryByUser',
          'TransactionRequests',
          'Account',
        ],
      }),
      declineTransactionRequest: builder.mutation<TransactionRequest, RevisionTransactionRequest>({
        query: ({ id, body }) => ({
          url: ACCOUNTS_API_URLS.DECLINE_TRANSACTION_REQUEST(id),
          method: REQUEST_METHODS.PATCH,
          body,
        }),
        invalidatesTags: ['UserLogInfo', 'TransactionRequests', 'DailyLimitRemaining', 'Account'],
      }),
      getDailyLimitRemaining: builder.query<DailyLimitRemaining, string>({
        query: (userUId) => {
          return {
            url: ACCOUNTS_API_URLS.DAILY_LIMIT_REMAINING(userUId),
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['DailyLimitRemaining']),
      }),
    }),
  });

export const {
  useGetAccountsQuery,
  useGetAccountByIdQuery,
  useUpdateAccountByIdMutation,
  useGetBagQualityByUserQuery,
  useGetFinancialTransactionsQuery,
  useLazyGetFinancialTransactionsQuery,
  useLazyGetWalletSummaryByUserQuery,
  useGetWalletDetailsByUserQuery,
  useGetWalletDetailsListQuery,
  useLazyGetBagDispenseHistoryByUserQuery,
  useLazyGetBagTransactionHistoryByUserQuery,
  useLazyGetTransferHistoryByUserQuery,
  useLazyGetUserLogInfoQuery,
  useAddUserCommentMutation,
  useLazyGetTransactionRequestsOnAccountQuery,
  useCreateTransactionRequestMutation,
  useAcceptTransactionRequestMutation,
  useDeclineTransactionRequestMutation,
  useGetDailyLimitRemainingQuery,
} = accountsApi;
