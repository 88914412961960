import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const activitiesModule = () => import('modules/activities');

export const activitiesRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.BAGS_DEPOSITED,
        lazy: lazyImportComponent(activitiesModule, 'BagsDeposited'),
      },
      {
        path: PATHS.BAGS_DEPOSITED_DETAILS,
        lazy: lazyImportComponent(activitiesModule, 'BagsDepositedDetails'),
      },
      {
        path: PATHS.BAGS_DEPOSITED_CONTENT,
        lazy: lazyImportComponent(activitiesModule, 'BagsContent'),
      },
      {
        path: PATHS.BAGS_PURCHASED,
        lazy: lazyImportComponent(activitiesModule, 'BagsPurchased'),
      },
      {
        path: PATHS.BAGS_PURCHASED_DETAILS,
        lazy: lazyImportComponent(activitiesModule, 'BagPurchasedDetails'),
      },
      {
        path: PATHS.DROP_OFF_SESSIONS,
        lazy: lazyImportComponent(activitiesModule, 'DropOffSessions'),
      },
      {
        path: PATHS.DROP_OFF_SESSIONS_DETAILS,
        lazy: lazyImportComponent(activitiesModule, 'DropOffSessionsDetails'),
      },
      {
        path: PATHS.MONEY_TRANSFER_DETAILS,
        lazy: lazyImportComponent(activitiesModule, 'MoneyTransferDetails'),
      },
      {
        path: PATHS.MONEY_TRANSFERS,
        lazy: lazyImportComponent(activitiesModule, 'MoneyTransfers'),
      },
    ],
  },
];
