import { quickDropAdminApi } from 'config/api';
import { REQUEST_METHODS } from 'modules/common';
import { USERS_API_URLS } from 'modules/users/constants';
import { PermissionsByGroups } from 'modules/users/types';

export const permissionsApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['Permissions'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPermissionsByGroups: builder.query<PermissionsByGroups, void>({
        query: () => ({
          url: USERS_API_URLS.USER_GROUPS,
          method: REQUEST_METHODS.GET,
        }),
        providesTags: (_result, error) => (error ? [] : ['Permissions']),
      }),
      deleteGroup: builder.mutation<{ success: boolean; id: string }, string>({
        query(id) {
          return {
            url: `${USERS_API_URLS.USER_GROUPS}/${id}`,
            method: REQUEST_METHODS.DELETE,
          };
        },
        invalidatesTags: (_result, error) => (error ? [] : ['Permissions']),
      }),
      addNewPermissionByGroups: builder.mutation<boolean, PermissionsByGroups>({
        query: (body) => ({
          url: USERS_API_URLS.USER_GROUPS,
          body,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['Permissions']),
      }),
    }),
  });

export const {
  useGetPermissionsByGroupsQuery,
  useLazyGetPermissionsByGroupsQuery,
  useAddNewPermissionByGroupsMutation,
  useDeleteGroupMutation,
} = permissionsApi;
