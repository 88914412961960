import { FC } from 'react';

export enum PATH_PARAMS {
  STATUS = 'status',
  LAT = 'lat',
  LNG = 'lng',
  ID = 'id',
  DEVICE_ID = 'deviceId',
}

export enum PATHS {
  DASHBOARD = '/',
  LOGIN = '/login',
  USERS = '/user-management/users',
  USER_BY_ID = '/user-management/users/:id',
  GROUPS = '/user-management/groups',
  PERMISSIONS = '/user-management/permissions',
  STATE_RATES = '/prices/state-rates',
  OPERATOR_RATES = '/prices/operator-rates',
  PROCESSING_FEE = '/processing-fee',
  SUPPORT_CONTACT = '/support-contact',
  SEND_NOTIFICATIONS = '/send-notifications',
  SEND_NOTIFICATIONS_HISTORY = '/send-notifications-history',
  LOCATIONS = '/locations',
  NEW_LOCATION = '/locations/new',
  LOCATIONS_STATUS = '/locations/:status',
  LOCATIONS_MAP_PREVIEW = 'locations/map/preview',
  LOCATION_DETAILS = 'locations/detail/:id',
  BAGS_DEPOSITED = '/activities/bags-deposited',
  BAGS_PURCHASED = '/activities/bags-purchased',
  BAGS_PURCHASED_DETAILS = '/activities/bags-purchased/:id',
  ACCOUNTS = '/accounts',
  ACCOUNTS_BY_STATUS = '/accounts/:status',
  ACCOUNT_DETAIL = '/accounts/detail',
  FINANCIAL_TRANSACTIONS = '/accounts/transactions/:id',
  ACCOUNT_BY_ID = '/accounts/detail/:id',
  BAGS_DEPOSITED_DETAILS = '/activities/bags-deposited/:id',
  BAGS_DEPOSITED_CONTENT = '/activities/bags-deposited/:id/content',
  DROP_OFF_SESSIONS = '/activities/drop-off-sessions',
  DROP_OFF_SESSIONS_DETAILS = '/activities/drop-off-sessions/:id',
  MONEY_TRANSFERS = '/activities/money-transfers',
  WALLET_DETAILS = '/accounts/detail/:id/wallet',
  MONEY_TRANSFER_DETAILS = '/activities/money-transfers/:id',
  OPERATORS = '/operators',
  NEW_OPERATOR = '/operators/new',
  OPERATORS_STATUS = '/operators/:status',
  OPERATOR_DETAILS = '/operators/detail/:id',
  SCANNERS = '/scanners',
  SCANNERS_LIST = '/scanners/:status',
  ADD_NEW_SCANNER = '/scanners/new',
  SCANNER_DETAILS = '/scanners/detail',
  SCANNER_DETAILS_BY_ID = '/scanners/detail/:deviceId',
  CERTIFICATES = '/certificates',
  CERTIFICATES_LIST = '/certificates/:status',
  REPORTS = '/reports',
  REPORTS_DAILY = '/reports/daily',
  REPORTS_ACTIVITIES = '/reports/activities',
  IP_ADDRESSES = '/ip-addresses',
  NOT_ALLOWED_IP_ADDRESS = '/not-allowed-ip-address',
}

type ExportsFC<T extends string> = {
  [key in T]: FC;
};

export const lazyImportComponent =
  <K extends string>(
    importPromise: Promise<ExportsFC<K>> | (() => Promise<ExportsFC<K>>),
    componentName: K,
  ) =>
  async () => {
    const imported =
      typeof importPromise === 'function' ? await importPromise() : await importPromise;
    return { Component: imported[componentName] };
  };
