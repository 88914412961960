/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import { DetailedHTMLProps, FC, ImgHTMLAttributes } from 'react';

interface ImageProps
  extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string;
  alt: string;
}

export const Image: FC<ImageProps> = ({ ...props }) => {
  const styling =
    props.onClick === undefined ? props.className : `${props.className} cursor-pointer`;

  return <img {...props} className={styling} />;
};
