import 'config/translation';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor } from 'config/store';
import { useWebsocket } from 'modules/common';

import { RootRouter } from './router';

const rootRouter = createBrowserRouter(RootRouter());

function App() {
  useWebsocket();
  return (
    <PersistGate persistor={persistor} loading={null}>
      <RouterProvider router={rootRouter} />
    </PersistGate>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
