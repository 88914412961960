/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import {
  ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTION_TABLE_KEYS,
  AccountWalletDetailsListState,
} from 'modules/accounts/types';
import {
  DATE_RANGE_TYPES,
  ORDER,
  calculateDateBack,
  emptySearchAndFilters,
  now,
} from 'modules/common';

const initialState: AccountWalletDetailsListState = {
  page: 1,
  sorting: {
    sort: ACCOUNT_WALLET_DETAILS_LIST_SORT_OPTION_TABLE_KEYS.TIMESTAMP,
    order: ORDER.DESC,
  },
  searchAndFilters: {
    ...emptySearchAndFilters,
    addFilter: {
      ...emptySearchAndFilters.addFilter,
      dateRangeType: DATE_RANGE_TYPES.LAST_30_DAYS,
      dateRange: {
        startDate: calculateDateBack(30).toISOString(),
        endDate: now().toISOString(),
      },
    },
  },
};

export const accountWalletDetailsListSlice = createSlice({
  name: 'accountWalletDetailsList',
  initialState,
  reducers: {
    setAccountWalletDetailsListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setAccountWalletDetailsListPage: (state, { payload }) => {
      state.page = payload;
    },
    setAccountWalletDeatailsListSearchAndFilters: (state, { payload }) => {
      state.searchAndFilters = payload;
    },
  },
});

export const accountWalletDetailsListReducer = accountWalletDetailsListSlice.reducer;

export const {
  setAccountWalletDetailsListPage,
  setAccountWalletDetailsListSorting,
  setAccountWalletDeatailsListSearchAndFilters,
} = accountWalletDetailsListSlice.actions;

export const selectAccountWalletDetailsList = (state: RootState) => state.accountWalletDetailsList;
