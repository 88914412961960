import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const devicesModule = () => import('modules/devices');

export const devicesRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.SCANNERS_LIST,
        lazy: lazyImportComponent(devicesModule, 'ScanningDevices'),
      },
      {
        path: PATHS.SCANNER_DETAILS_BY_ID,
        lazy: lazyImportComponent(devicesModule, 'ScanningDeviceDetails'),
      },
      {
        path: PATHS.ADD_NEW_SCANNER,
        lazy: lazyImportComponent(devicesModule, 'NewScanningDevice'),
      },
    ],
  },
];
