/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import {
  MONEY_TRANSFERS_SORT_OPTION_TABLE_KEYS,
  MoneyTransfersState,
} from 'modules/activities/types';
import { ORDER, emptySearchAndFilters } from 'modules/common';

const initialState: MoneyTransfersState = {
  page: 1,
  sorting: { sort: MONEY_TRANSFERS_SORT_OPTION_TABLE_KEYS.TIMESTAMP, order: ORDER.DESC },
  searchAndFilters: { ...emptySearchAndFilters },
};

export const moneyTransfersListSlice = createSlice({
  name: 'moneyTransfers',
  initialState,
  reducers: {
    setMoneyTransfersSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setMoneyTransfersPage: (state, { payload }) => {
      state.page = payload;
    },
    setMoneyTransferListSearchAndFilters: (state, { payload }) => {
      state.searchAndFilters = payload;
    },
  },
});

export const moneyTransfersReducer = moneyTransfersListSlice.reducer;

export const {
  setMoneyTransfersSorting,
  setMoneyTransfersPage,
  setMoneyTransferListSearchAndFilters,
} = moneyTransfersListSlice.actions;

export const selectMoneyTransfers = (state: RootState) => state.moneyTransfersList;
