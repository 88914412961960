/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { emptySearchAndFilters } from 'modules/common/constants';
import { ORDER } from 'modules/common/types';
import { USER_SORT_OPTION_TABLE_KEYS, UsersListState } from 'modules/users/types';

const initialState: UsersListState = {
  page: 1,
  sorting: { sort: USER_SORT_OPTION_TABLE_KEYS.USER_SINCE, order: ORDER.DESC },
  searchAndFilters: { ...emptySearchAndFilters },
};

export const usersListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    setUsersListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setUsersListPage: (state, { payload }) => {
      state.page = payload;
    },
    setUsersSearchAndFilters: (state, { payload }) => {
      state.searchAndFilters = payload;
    },
  },
});

export const usersListReducer = usersListSlice.reducer;

export const { setUsersListSorting, setUsersListPage, setUsersSearchAndFilters } =
  usersListSlice.actions;

export const selectUsersList = (state: RootState) => state.usersList;
