import * as yup from 'yup';

import { EmailSchema, StringSchemaGenerator } from 'modules/common';
import { ADD_OPERATOR_FIELDS, OperatorFields } from 'modules/operators/types';

export const AddNewOperatorSchema: yup.Schema<OperatorFields> = yup.object().shape({
  name: StringSchemaGenerator(ADD_OPERATOR_FIELDS.NAME),
  address: StringSchemaGenerator(ADD_OPERATOR_FIELDS.ADDRESS),
  mainContactName: StringSchemaGenerator(ADD_OPERATOR_FIELDS.MAIN_CONTACT),
  phone: StringSchemaGenerator(ADD_OPERATOR_FIELDS.PHONE),
  email: EmailSchema,
  noteData: yup.string().notRequired().nonNullable(),
});
