import { ORDER, PageInfo } from 'modules/common';
import { SCANNING_DEVICE_STATUS } from 'modules/devices';

export enum CERTIFICATE_STATUSES {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

export interface DeviceOnCertifiate {
  deviceId: string;
  status: SCANNING_DEVICE_STATUS;
}
export interface Certificate {
  _id: string;
  devices: DeviceOnCertifiate[];
  createdAt: Date;
  updatedAt: Date;
  blockedAt: Date;
  status: CERTIFICATE_STATUSES;
  certificateUId: string;
}

export interface CertificatesList {
  meta: PageInfo;
  data: Certificate[];
}

export enum CERTIFICATE_LIST_SORT_OPTIONS {
  NUMBER_OF_DEVICES_ASSIGNED = 'numberOfDevicesAssigned',
  CREATED_AT = 'createdAt',
}

export interface CertificatesListQueryParams {
  page?: number;
  status?: CERTIFICATE_STATUSES | string;
  take?: number;
  search?: string;
  sort?: CERTIFICATE_LIST_SORT_OPTIONS;
  order?: ORDER;
}

export interface CertificateUpdate {
  status?: CERTIFICATE_STATUSES;
}

export interface CertificatePatchRequest {
  certificateUId: string;
  body: CertificateUpdate;
}

export enum ADD_NEW_CERTIFICATE_FIELDS {
  CERTIFICATE_UID = 'certificateUId',
}

export type AddNewCertificateFields = Record<ADD_NEW_CERTIFICATE_FIELDS, string>;

export interface CertificateListState {
  page: number;
}
