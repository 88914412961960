/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import {
  DROP_OFF_SESSIONS_SORT_OPTION_TABLE_KEYS,
  DropOffSessionsState,
} from 'modules/activities/types';
import { ORDER } from 'modules/common';

const initialState: DropOffSessionsState = {
  page: 1,
  sorting: { sort: DROP_OFF_SESSIONS_SORT_OPTION_TABLE_KEYS.SESSION_START, order: ORDER.DESC },
};

export const dropOffSessionListSlice = createSlice({
  name: 'dropOffSessions',
  initialState,
  reducers: {
    setDropOffSessionsSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setDropOffSessionsPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const dropOffSessionsReducer = dropOffSessionListSlice.reducer;

export const { setDropOffSessionsSorting, setDropOffSessionsPage } =
  dropOffSessionListSlice.actions;

export const selectDropOffSessions = (state: RootState) => state.dropOffSessionList;
