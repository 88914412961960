import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useIsFirstRender } from 'usehooks-ts';

import { getAccessToken, getIsLoggedIn } from 'modules/auth';
import { addToEvents } from 'modules/export';
import { ExportEvent } from 'modules/export/types';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export const useWebsocket = () => {
  const accessToken = useAppSelector(getAccessToken);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const dispatch = useAppDispatch();
  const isFirstRender = useIsFirstRender();

  const URL = import.meta.env.VITE_APP_API_URL;

  const generateSocketIObody = (token?: string) => ({
    autoConnect: false,
    auth: {
      token: `Bearer ${token}`,
    },
    withCredentials: true,
    transports: ['websocket'],
  });

  const [socket, setSocket] = useState(() => io(URL, generateSocketIObody(accessToken)));
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    if (socket.connected) {
      socket.disconnect();
    }

    if (isLoggedIn) {
      setSocket(io(URL, generateSocketIObody(accessToken)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isFirstRender) {
      socket.connect();
    }

    const onConnect = () => {
      setIsConnected(true);
    };

    const onDisconnect = () => {
      setIsConnected(false);
    };

    const onExportEvent = (value: ExportEvent) => {
      dispatch(addToEvents(value));
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('exports', onExportEvent);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('exports', onExportEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return { isConnected };
};
