import { ORDER, QueryFilterParams, SearchAndFiltersState, Sorting } from 'modules/common';

export enum NOTIFICATION_PLATFORMS {
  EMAIL = 'email',
  MOBILE_PUSH_NOTIFICATION = 'mobile',
  IN_APP = 'websocket',
}

export enum SEND_TO_TYPE {
  EVERY_CUSTOMER = 'every_customer',
  SINGLE_CUSTOMER = 'single_customer',
}

export enum SEND_CUSTOM_NOTIFICATION_FIELDS {
  SEND_TO_TYPE_FIELD = 'sendToType',
  SEND_TO_USERUID = 'sendToUserUId',
  TITLE_TEXT = 'titleText',
  BODY_TEXT = 'bodyText',
  ACCEPT_TEXT = 'acceptText',
  PLATFORM_TYPE = 'platformType',
}

export interface SendNotificationFields {
  [SEND_CUSTOM_NOTIFICATION_FIELDS.SEND_TO_TYPE_FIELD]: SEND_TO_TYPE;
  [SEND_CUSTOM_NOTIFICATION_FIELDS.SEND_TO_USERUID]?: string;
  [SEND_CUSTOM_NOTIFICATION_FIELDS.TITLE_TEXT]: string;
  [SEND_CUSTOM_NOTIFICATION_FIELDS.BODY_TEXT]: string;
  [SEND_CUSTOM_NOTIFICATION_FIELDS.ACCEPT_TEXT]?: string;
  [SEND_CUSTOM_NOTIFICATION_FIELDS.PLATFORM_TYPE]: string | null;
}
export type SendNotificationBody = SendNotificationFields;

export enum CUSTOM_NOTIFICATIONS_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
}

export const customNotificationsListSortOptions = {
  createdAt: CUSTOM_NOTIFICATIONS_LIST_SORT_OPTIONS.CREATED_AT,
};

export type CustomNotificationsListSorting = Sorting<CUSTOM_NOTIFICATIONS_LIST_SORT_OPTIONS>;

export interface CustomNotificationsListQueryParams {
  page?: number;
  take?: number;
  sort?: CUSTOM_NOTIFICATIONS_LIST_SORT_OPTIONS;
  order?: ORDER;
  filters?: QueryFilterParams;
}

export interface CustomNotificationsListState {
  page: number;
  sorting?: CustomNotificationsListSorting;
  searchAndFilters: SearchAndFiltersState;
}
