import { quickDropAdminApi } from 'config/api';
import {
  ForgottenPasswordBody,
  ForgottenPasswordReponse,
  LoginBody,
  LoginResponse,
  LogoutResponse,
  WhoAmIResponse,
  AUTH_API_URLS,
  RefreshTokensRequest,
} from 'modules/auth';
import { REQUEST_METHODS } from 'modules/common';
import { resetExportStore } from 'modules/export';

export const authApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['WhoAmI'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      twoFactorAuthRequest: builder.mutation<boolean, LoginBody>({
        query: (body) => ({
          body,
          url: AUTH_API_URLS.TWO_FACTOR_AUTH_REQUEST,
          method: REQUEST_METHODS.POST,
        }),
        extraOptions: { disableDefaultSuccessToaster: true },
      }),
      login: builder.mutation<LoginResponse, LoginBody>({
        query: (body) => ({
          body,
          url: AUTH_API_URLS.LOGIN,
          method: REQUEST_METHODS.POST,
        }),
      }),
      refreshTokensManually: builder.mutation<LoginResponse, RefreshTokensRequest>({
        query: ({ refreshToken, stayLoggedIn }) => ({
          body: { stayLoggedIn },
          headers: { authorization: `Bearer ${refreshToken}` },
          url: AUTH_API_URLS.REFRESH_AUTH,
          method: REQUEST_METHODS.POST,
        }),
      }),
      logout: builder.mutation<LogoutResponse, void>({
        query: () => ({
          url: AUTH_API_URLS.LOGOUT,
          method: REQUEST_METHODS.POST,
        }),
        async onQueryStarted(_logoutArg, { dispatch, queryFulfilled }) {
          if (await queryFulfilled) {
            dispatch(quickDropAdminApi.util.resetApiState());
            dispatch(resetExportStore());
          }
        },
      }),
      whoAmI: builder.query<WhoAmIResponse, void>({
        query: () => ({ url: AUTH_API_URLS.WHO_AM_I }),
        async onQueryStarted(_loginBody, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch {
            dispatch(authApi.endpoints.logout.initiate());
          }
        },
        providesTags: (_result, error) => (error ? [] : ['WhoAmI']),
      }),
      forgottenPassword: builder.mutation<ForgottenPasswordReponse, ForgottenPasswordBody>({
        query: (body) => ({
          body,
          url: AUTH_API_URLS.FORGOT_PASSWORD,
          method: REQUEST_METHODS.POST,
        }),
      }),
    }),
  });

export const {
  useLoginMutation,
  useTwoFactorAuthRequestMutation,
  useRefreshTokensManuallyMutation,
  useLogoutMutation,
  useWhoAmIQuery,
  useLazyWhoAmIQuery,
  useForgottenPasswordMutation,
} = authApi;
