export enum EXPORT_STATUS {
  CREATED = 'created',
  FAILED = 'failed',
  FINISHED = 'finished',
}

export type ExportEvent = {
  downloadLink: string;
  exportId: string;
  newStatus: EXPORT_STATUS;
};

export interface ExportResponse {
  id: string;
  createdAt: Date;
  downloadAt?: Date;
  fileName: string;
  path: string;
  status: EXPORT_STATUS;
  updatedAt: Date;
  userUId: string;
}

export interface ExportEventQueueItem extends ExportResponse {
  isLoading: boolean;
  error?: string;
}
export interface ExportState {
  events: {
    exportEventQueue: ExportEventQueueItem[];
    exportEvents: ExportEvent[];
    pendingCompletedExportEvents?: ExportEvent[];
  };
}
