/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ORDER } from 'modules/common';
import { DAILY_SUMMARY_SORT_OPTIONS, DailySummaryState } from 'modules/reports/types';

const initialState: DailySummaryState = {
  page: 1,
  sorting: { sort: DAILY_SUMMARY_SORT_OPTIONS.CREATED_AT, order: ORDER.DESC },
  filters: {
    certificate: undefined,
    type: undefined,
    from: undefined,
    until: undefined,
  },
};

export const dailySummarySlice = createSlice({
  name: 'dailySummary',
  initialState,
  reducers: {
    setDailySummarySorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setDailySummaryPage: (state, { payload }) => {
      state.page = payload;
    },
    setDailySummaryCertificate: (state, { payload }) => {
      state.filters.certificate = payload;
    },
    setDailySummaryType: (state, { payload }) => {
      state.filters.type = payload;
    },
    setDailySummaryDate: (state, { payload }) => {
      state.filters.from = payload.from;
      state.filters.until = payload.until;
    },
  },
});

export const dailySummaryReducer = dailySummarySlice.reducer;

export const {
  setDailySummarySorting,
  setDailySummaryPage,
  setDailySummaryCertificate,
  setDailySummaryDate,
  setDailySummaryType,
} = dailySummarySlice.actions;

export const selectDailySummary = (state: RootState) => state.dailySummary;
