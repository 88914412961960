/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'config/store';

import { exportApi } from '../api';
import { ExportEvent, ExportResponse, ExportState } from '../types';

const initialState: ExportState = {
  events: {
    exportEventQueue: [],
    exportEvents: [],
  },
};

const removeFromPendingCompletedExportEvents = (state: ExportState, id: string) => {
  state.events.pendingCompletedExportEvents = state.events.pendingCompletedExportEvents?.filter(
    ({ exportId }) => exportId !== id,
  );
};

const handleAddToQueue = (state: ExportState, { payload }: PayloadAction<ExportResponse>) => {
  let newEvent = { isLoading: true, ...payload };

  const index = state.events.pendingCompletedExportEvents?.findIndex(
    ({ exportId }) => exportId === payload.id,
  );
  const isExportEventReady = index !== undefined && index !== -1;

  if (isExportEventReady) {
    newEvent = {
      ...newEvent,
      isLoading: false,
      ...state.events.pendingCompletedExportEvents?.[index],
    };

    removeFromPendingCompletedExportEvents(state, payload.id);
  }

  state.events.exportEventQueue = [...state.events.exportEventQueue, newEvent];
};

const handleCompletedExport = (state: ExportState, { payload }: PayloadAction<ExportEvent>) => {
  const index = state.events.exportEventQueue.findIndex(({ id }) => id === payload.exportId);

  if (index !== -1) {
    state.events.exportEventQueue[index].isLoading = false;
    state.events.exportEvents = [...state.events.exportEvents, payload];
  } else {
    state.events.pendingCompletedExportEvents = [
      ...(state.events.pendingCompletedExportEvents || []),
      payload,
    ];
  }
};

export const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    addToExportEventQueue: (state, action) => {
      handleAddToQueue(state, action);
    },
    addToEvents: (state, action) => {
      handleCompletedExport(state, action);
    },
    addEventError: (state, action) => {
      const toUpdate = state.events.exportEventQueue.find(({ id }) => id === action.payload.id);
      if (toUpdate) {
        state.events.exportEventQueue = [
          ...state.events.exportEventQueue.filter(({ id }) => id !== action.payload.id),
          { ...toUpdate, error: action.payload.message, isLoading: false },
        ];
      }
    },
    removeFromExportEvent: (state, action) => {
      state.events.exportEvents = state.events.exportEvents.filter(
        ({ exportId }) => exportId !== action.payload,
      );
    },
    removeFromExportEventQueue: (state, action) => {
      state.events.exportEventQueue = state.events.exportEventQueue.filter(
        ({ id }) => id !== action.payload,
      );
    },
    setExportEventLoading: (state, action) => {
      state.events.exportEventQueue = state.events.exportEventQueue.map((event) =>
        event.id === action.payload ? { ...event, isLoading: true } : event,
      );
    },
    resetExportStore: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(exportApi.endpoints.exportDevices.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportDropOffs.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportUsers.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportAccounts.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportOperators.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportWalletDetails.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportTransactions.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportBagsPurchased.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportDropOffSessions.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportMoneyTransfers.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportBagDeposited.matchFulfilled, handleAddToQueue);
    builder.addMatcher(exportApi.endpoints.exportDailySummaries.matchFulfilled, handleAddToQueue);
    builder.addMatcher(
      exportApi.endpoints.exportDailyActivitiesSummary.matchFulfilled,
      handleAddToQueue,
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  addToExportEventQueue,
  addToEvents,
  addEventError,
  removeFromExportEvent,
  removeFromExportEventQueue,
  resetExportStore,
  setExportEventLoading,
} = exportSlice.actions;

export const exportReducer = exportSlice.reducer;

export const getExportEventQueue = (state: RootState) => state.export.events.exportEventQueue;
export const getExportEvents = (state: RootState) => state.export.events.exportEvents;
