import { addDays, addMinutes, startOfDay } from 'date-fns';
import * as yup from 'yup';

import {
  OpeningHours,
  OpeningHoursCustomDay,
  OpeningHoursDay,
  StringSchemaGenerator,
  VALIDATION_ERRORS,
  ZipSchema,
  getErrorMessage,
} from 'modules/common';
import {
  ADD_LOCATION_FIELDS,
  LocationFields,
  OPENING_HOURS_FIELDS,
  UNIT_TYPES,
} from 'modules/locations/types';

const OpeningHoursDaySchema: yup.Schema<OpeningHoursDay> = yup
  .object()
  .shape({
    from: yup.number().min(0).max(1440).required().nonNullable(),
    to: yup
      .number()
      .min(0)
      .max(1440)
      // TODO Remove when constraint depending on client answer
      .when('from', ([from], schema) =>
        schema.min(
          from,
          getErrorMessage(OPENING_HOURS_FIELDS.TO, VALIDATION_ERRORS.CLOSING_MUST_BE_AFTER_OPENING),
        ),
      )
      .required()
      .nonNullable(),
    is24HourOpening: yup.bool().required().nonNullable(),
    isClosed: yup.bool().required().nonNullable(),
  })
  .test({
    name: 'closingAnd24HoursFlagsExclusive',
    message: getErrorMessage(
      OPENING_HOURS_FIELDS.IS_CLOSED,
      VALIDATION_ERRORS.CLOSED_24H_FLAGS_EXCLUSIVE,
    ),
    test: ({ isClosed, is24HourOpening }) => !(isClosed && is24HourOpening),
  });

const OpeningHoursCustomDaySchema: yup.Schema<OpeningHoursCustomDay> = yup
  .object()
  .shape({
    date: yup
      .string()
      .nonNullable()
      .test({
        name: 'ensureValidISODateInFuture',
        message: getErrorMessage(
          OPENING_HOURS_FIELDS.DATE,
          VALIDATION_ERRORS.MUST_BE_VALID_DATE_FROM_YESTERDAY,
        ),
        test: (value) => {
          if (value === undefined) return false;
          const parsed = new Date(value);
          const localTime = new Date();
          const utcTime = addMinutes(localTime, localTime.getTimezoneOffset());
          if (!Number.isNaN(parsed.getTime()) && parsed >= startOfDay(addDays(utcTime, -1))) {
            return true;
          }
          return false;
        },
      })
      .required(),
    from: yup
      .number()
      .min(0)
      .max(1440)
      .required(getErrorMessage(OPENING_HOURS_FIELDS.FROM, VALIDATION_ERRORS.REQUIRED_FIELD))
      .nonNullable(),
    to: yup
      .number()
      .min(0)
      .max(1440)
      // TODO Remove when constraint depending on client answer
      .when('from', ([from], schema) =>
        schema.min(
          from,
          getErrorMessage(OPENING_HOURS_FIELDS.TO, VALIDATION_ERRORS.CLOSING_MUST_BE_AFTER_OPENING),
        ),
      )
      .required(getErrorMessage(OPENING_HOURS_FIELDS.TO, VALIDATION_ERRORS.REQUIRED_FIELD))
      .nonNullable(),
    is24HourOpening: yup.bool().required().nonNullable(),
    isClosed: yup.bool().required().nonNullable(),
  })
  .test({
    name: 'closingAnd24HoursFlagsExclusive',
    message: getErrorMessage(
      OPENING_HOURS_FIELDS.IS_CLOSED,
      VALIDATION_ERRORS.CLOSED_24H_FLAGS_EXCLUSIVE,
    ),
    test: ({ isClosed, is24HourOpening }) => !(isClosed && is24HourOpening),
  });

export const OpeningHoursSchema: yup.Schema<OpeningHours> = yup
  .object()
  .shape({
    monday: OpeningHoursDaySchema,
    tuesday: OpeningHoursDaySchema,
    wednesday: OpeningHoursDaySchema,
    thursday: OpeningHoursDaySchema,
    friday: OpeningHoursDaySchema,
    saturday: OpeningHoursDaySchema,
    sunday: OpeningHoursDaySchema,
    customDays: yup.array(OpeningHoursCustomDaySchema).nonNullable().required(),
  })
  .required(getErrorMessage(ADD_LOCATION_FIELDS.OPENING_HOURS, VALIDATION_ERRORS.REQUIRED_FIELD));

export const AddNewLocationSchema: yup.Schema<LocationFields> = yup.object().shape({
  zip: ZipSchema,
  city: StringSchemaGenerator(ADD_LOCATION_FIELDS.CITY),
  title: StringSchemaGenerator(ADD_LOCATION_FIELDS.TITLE),
  address: StringSchemaGenerator(ADD_LOCATION_FIELDS.ADDRESS),
  organizationUId: StringSchemaGenerator(ADD_LOCATION_FIELDS.ORGANIZATIONUID),
  lat: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(-90, getErrorMessage(ADD_LOCATION_FIELDS.LAT, VALIDATION_ERRORS.LAT_MIN_MAX))
    .max(90, getErrorMessage(ADD_LOCATION_FIELDS.LAT, VALIDATION_ERRORS.LAT_MIN_MAX))
    .required(getErrorMessage(ADD_LOCATION_FIELDS.LAT, VALIDATION_ERRORS.REQUIRED_FIELD)),
  lon: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(-180, getErrorMessage(ADD_LOCATION_FIELDS.LON, VALIDATION_ERRORS.LON_MIN_MAX))
    .max(180, getErrorMessage(ADD_LOCATION_FIELDS.LON, VALIDATION_ERRORS.LON_MIN_MAX))
    .required(getErrorMessage(ADD_LOCATION_FIELDS.LON, VALIDATION_ERRORS.REQUIRED_FIELD)),
  showOnMap: yup
    .boolean()
    .required(getErrorMessage(ADD_LOCATION_FIELDS.SHOW_ON_MAP, VALIDATION_ERRORS.REQUIRED_FIELD)),
  type: yup
    .mixed<UNIT_TYPES>()
    .oneOf(Object.values(UNIT_TYPES))
    .nonNullable()
    .required(getErrorMessage(ADD_LOCATION_FIELDS.UNIT_TYPE, VALIDATION_ERRORS.REQUIRED_FIELD)),
  unit: yup.string().notRequired().nonNullable(),
  notes: yup.string().notRequired().nonNullable(),
  openingHours: OpeningHoursSchema,
});
