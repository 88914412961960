import * as yup from 'yup';

import { ForgottenPasswordFields, LoginFields } from 'modules/auth/types';
import { EmailSchema, CheckBoxSchema } from 'modules/common';
import { VALIDATION_ERRORS, getErrorMessage } from 'modules/common/utils';

export const PasswordSchema: yup.Schema<string> = yup
  .string()
  .required(getErrorMessage('password', VALIDATION_ERRORS.REQUIRED_FIELD));

export const LoginSchema: yup.Schema<LoginFields> = yup.object().shape({
  email: EmailSchema,
  password: PasswordSchema,
  stayLoggedIn: CheckBoxSchema,
  verificationCode: yup.string(),
});

export const ForgottenPasswordSchema: yup.Schema<ForgottenPasswordFields> = yup.object().shape({
  email: EmailSchema,
});
