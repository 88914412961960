import { LOG_TYPE, PageInfo } from 'modules/common';

export interface SupportOpeningHours {
  from: number;
  to: number;
}

export interface SupportContact {
  supportOpeningHours: SupportOpeningHours;
  email: string;
  phoneNumber: string;
  createdAt: string;
}

export type SupportContactForm = Omit<SupportContact, 'createdAt'>;

export interface SupportContactLog extends SupportContact {
  _id: string;
  type: LOG_TYPE;
  loggingUser?: {
    firstName: string;
    lastName: string;
    userUId: string;
  };
}

export interface SupportContactLogList {
  data: SupportContactLog[];
  meta: PageInfo;
}

export interface SupportContactLogInfoQueryParams {
  page?: number;
}

export interface SupportContactLogState {
  page: number;
  lastPage: number;
  supportContactLogs: SupportContactLog[];
}

export enum SUPPORT_CONTACT_FIELDS {
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  SUPPORT_OPENING_HOURS = 'supportOpeningHours',
}
