import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const usersModule = () => import('modules/users');

export const usersRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.USERS,
        lazy: lazyImportComponent(usersModule, 'Users'),
      },
      {
        path: PATHS.USER_BY_ID,
        lazy: lazyImportComponent(usersModule, 'UserById'),
      },
      {
        path: PATHS.GROUPS,
        lazy: lazyImportComponent(usersModule, 'Groups'),
      },
      {
        path: PATHS.PERMISSIONS,
        lazy: lazyImportComponent(usersModule, 'Permissions'),
      },
    ],
  },
];
