import { ORDER, PageInfo, QueryFilterParams, SearchAndFiltersState, Sorting } from 'modules/common';

export interface IpAddress {
  _id: string;
  ip: string;
  name: string;
  createdAt: Date;
}

export interface IpAddressesList {
  meta: PageInfo;
  data: IpAddress[];
}

export enum IP_ADDRESS_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  NAME = 'name',
  IP_ADDRESS = 'ip',
}

export const ipAddressesListSortOptions = {
  createdAt: IP_ADDRESS_LIST_SORT_OPTIONS.CREATED_AT,
  name: IP_ADDRESS_LIST_SORT_OPTIONS.NAME,
  ip: IP_ADDRESS_LIST_SORT_OPTIONS.IP_ADDRESS,
};

export type IpAddressesListSorting = Sorting<IP_ADDRESS_LIST_SORT_OPTIONS>;

export interface IpAddressesListQueryParams {
  page?: number;
  take?: number;
  sort?: IP_ADDRESS_LIST_SORT_OPTIONS;
  order?: ORDER;
  filters?: QueryFilterParams;
}

export enum ADD_NEW_IP_ADDRESS_FIELDS {
  IP_ADDRESS = 'ip',
  NAME = 'name',
}

export type AddNewIpAddressFields = Record<ADD_NEW_IP_ADDRESS_FIELDS, string>;

export interface IpAddressListState {
  page: number;
  sorting?: IpAddressesListSorting;
  searchAndFilters: SearchAndFiltersState;
}

export interface AddNewIpAddressRequest {
  name: string;
  ip: string;
}

export const DUPLICATE_IP_ADDRESS_ERROR_CODE = 422;
