import { FC } from 'react';

export const Progress: FC = () => {
  return (
    <div className="w-full">
      <div className="h-1.5 w-full bg-neutral-500 overflow-hidden">
        <div className="animate-progress w-full h-full bg-neutral-300 origin-left-right" />
      </div>
    </div>
  );
};
