import * as yup from 'yup';

import { PasswordSchema } from 'modules/auth';
import {
  EmailSchema,
  FirstNameSchema,
  LastNameSchema,
  PhoneNumberSchema,
  ZipSchema,
  NameSchema,
  DescriptionSchema,
  StringSchemaGenerator,
} from 'modules/common';
import { AddNewGroupFields, AddNewUserFields, EditUserFields } from 'modules/users/types';

export const EditUserSchema: yup.Schema<EditUserFields> = yup.object().shape({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  phoneNumber: PhoneNumberSchema,
  organizationUId: StringSchemaGenerator('organizationUId'),
  groupId: StringSchemaGenerator('groupId'),
  position: yup.string(),
});

export const AddNewUserSchema: yup.Schema<AddNewUserFields> = yup.object().shape({
  email: EmailSchema,
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  zip: ZipSchema,
  phoneNumber: PhoneNumberSchema,
  organizationUId: StringSchemaGenerator('organizationUId'),
  groupId: StringSchemaGenerator('groupId'),
  position: yup.string(),
  password: PasswordSchema,
});

export const AddNewGroupSchema: yup.Schema<AddNewGroupFields> = yup.object().shape({
  name: NameSchema,
  description: DescriptionSchema,
});

export const ModifyGroupSchema = yup.array().of(
  yup.object().shape({
    name: yup.string().required(),
    description: yup.string().max(255),
  }),
);
