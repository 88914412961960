import { USER_ROLE_KEYS } from 'modules/auth/types';
import { UserDetails } from 'modules/users/types';

export const USERS_API_URLS = {
  USER: '/admin/user',
  USER_GROUPS: '/admin/user-groups',
  USER_LOG_INFO: '/admin/user-log-info',
};

export const PERMISSION_TABLE_LABELS: USER_ROLE_KEYS[] = [
  USER_ROLE_KEYS.USER_MODIFICATION,
  USER_ROLE_KEYS.MANAGE_PRICES,
  USER_ROLE_KEYS.MANAGE_PAYOUTS,
  USER_ROLE_KEYS.DROP_OFF_MANAGEMENT,
  USER_ROLE_KEYS.DROPPED_BAG_MANAGEMENT,
  USER_ROLE_KEYS.FREE_BAG_MANAGEMENT,
  USER_ROLE_KEYS.BAG_SCANNERS,
  USER_ROLE_KEYS.ACTIVITIES,
  USER_ROLE_KEYS.MANAGE_USER,
  USER_ROLE_KEYS.MANAGE_CREDENTIALS,
  USER_ROLE_KEYS.SEND_CUSTOM_NOTIFICATION,
  USER_ROLE_KEYS.BAG_PROCESS_API_ACCESS_MANAGE,
  USER_ROLE_KEYS.TRANSACTION_MANAGEMENT,
];

export const USER_DETAILS_LABELS: (keyof UserDetails)[] = [
  'userUId',
  'type',
  'firstName',
  'lastName',
  'phoneNumber',
  'email',
  'createdAt',
  'emailConfirmedAt',
  'status',
  'organizations',
  'position',
  'sendDailyActivitiesSummaryReportViaEmail',
];
