import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Progress } from 'modules/common';

type ExportPopupProps = {
  isLoading: boolean;
  onClose: () => void;
  onDownload?: () => Promise<void>;
  fileName: string;
  isFailed?: boolean;
};

export const ExportPopup: FC<ExportPopupProps> = ({
  isLoading,
  onClose,
  onDownload,
  fileName,
  isFailed,
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-[400px] rounded text-xs shadow-md bg-neutral-300 text-black border border-neutral-100 h-fit relative z-50 right-0 top-0 mb-4">
      <div className="p-3">
        <h1 className="font-bold text-sm">{t('export.exports')}</h1>
        <div>
          <span className="mr-3 text-xs">{isFailed ? t('export.exportFailed') : fileName}</span>
          <div className="w-full text-center mt-3">
            <Button
              className="text-center bg-neutral-400 py-1 px-6 text-black  hover:bg-neutral-500 cursor-pointer text-xs mr-3"
              onClick={onClose}
              label="Cancel"
            />
            {!isLoading && !isFailed && (
              <Button
                className="text-center bg-neutral-400 py-1 px-6 text-black  hover:bg-neutral-500 cursor-pointer text-xs"
                label="Download"
                onClick={onDownload}
              />
            )}
          </div>
        </div>
      </div>
      {isLoading ? <Progress /> : null}
    </div>
  );
};
