import { quickDropAdminApi } from 'config/api';
import { authApi } from 'modules/auth';
import { CommentPostRequest, LogInfoQueryParams, REQUEST_METHODS } from 'modules/common';
import { OPERATORS_API_URLS } from 'modules/operators/constants';
import { resetOperatorLogs, setOperatorLogsListPage } from 'modules/operators/store';
import {
  OperatorFields,
  AddNewOperatorResponse,
  OperatorResponse,
  OperatorsList,
  OperatorsListQueryParams,
  OperatorPatchRequest,
  OperatorLogInfoList,
  OperatorLogInfo,
} from 'modules/operators/types';

export const operatorsApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['Operator', 'OperatorLogInfo', 'WhoAmI'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOperators: builder.query<OperatorsList, OperatorsListQueryParams>({
        query: ({ page, sort, order, status }) => {
          const params = {
            'pagination[page]': page,
            'filters[status][$in][]': status,
            [`sort[${sort}]`]: order,
          };

          return {
            url: OPERATORS_API_URLS.OPERATOR,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (!result ? [] : ['Operator']),
      }),
      getOperator: builder.query<OperatorResponse, string>({
        query: (id: string) => ({
          url: OPERATORS_API_URLS.OPERATOR_BY_ID(id),
          method: REQUEST_METHODS.GET,
        }),
        providesTags: (_result, error) => (error ? [] : ['Operator']),
      }),
      updateOperator: builder.mutation<OperatorResponse, OperatorPatchRequest>({
        query: (body) => {
          const { id, ...rest } = body;
          return {
            body: rest,
            url: OPERATORS_API_URLS.OPERATOR_BY_ID(id),
            method: REQUEST_METHODS.PATCH,
          };
        },
        invalidatesTags: (_result, error) => (error ? [] : ['Operator', 'OperatorLogInfo']),
        onQueryStarted({ id, ...update }, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            operatorsApi.util.updateQueryData('getOperator', id, (results) => {
              return { ...results, ...update };
            }),
          );
          queryFulfilled
            .then(() => {
              dispatch(setOperatorLogsListPage(1));
              dispatch(resetOperatorLogs());
            })
            .catch(updatedResult.undo);
        },
      }),
      addNewOperator: builder.mutation<AddNewOperatorResponse, OperatorFields>({
        query: ({ noteData, ...body }) => ({
          body: { ...body, noteData: { text: noteData } },
          url: OPERATORS_API_URLS.OPERATOR,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['Operator', 'WhoAmI']),
        onQueryStarted(_newOperator, { dispatch, queryFulfilled }) {
          queryFulfilled.then(() => {
            dispatch(authApi.endpoints.whoAmI.initiate());
          });
        },
      }),
      getOperatorLogInfo: builder.query<OperatorLogInfoList, LogInfoQueryParams>({
        query: ({ page, id }) => {
          const params = {
            'pagination[page]': page,
            'filters[id]': id,
          };
          return {
            url: OPERATORS_API_URLS.OPERATOR_LOG_INFO,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['OperatorLogInfo']),
      }),
      addOperatorComment: builder.mutation<OperatorLogInfo, CommentPostRequest>({
        query: (body) => ({
          url: OPERATORS_API_URLS.OPERATOR_LOG_INFO,
          body,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['OperatorLogInfo', 'Operator']),
      }),
    }),
  });

export const {
  useGetOperatorsQuery,
  useGetOperatorQuery,
  useUpdateOperatorMutation,
  useAddNewOperatorMutation,
  useLazyGetOperatorLogInfoQuery,
  useAddOperatorCommentMutation,
} = operatorsApi;
