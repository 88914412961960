import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const locationsModule = () => import('modules/locations');

export const locationsRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.NEW_LOCATION,
        lazy: lazyImportComponent(locationsModule, 'AddNewLocation'),
      },
      {
        path: PATHS.LOCATIONS_STATUS,
        lazy: lazyImportComponent(locationsModule, 'LocationsStatus'),
      },
      {
        path: PATHS.LOCATION_DETAILS,
        lazy: lazyImportComponent(locationsModule, 'LocationDetails'),
      },
      {
        path: PATHS.LOCATIONS_MAP_PREVIEW,
        lazy: lazyImportComponent(locationsModule, 'MapPreview'),
      },
    ],
  },
];
