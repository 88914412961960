import { FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm } from 'modules/common';
import {
  ADD_LOCATION_FIELDS,
  AddNewLocationBody,
  LocationFields,
  LOCATIONS_STATUSES,
  UNIT_TYPES,
} from 'modules/locations/types';
import { AddNewLocationSchema } from 'modules/locations/validation';
import { PATHS } from 'router/utils';

import { useAddNewLocationMutation } from '../api';

const defaultAddNewLocationFields: LocationFields = {
  zip: '',
  city: '',
  address: '',
  lat: '',
  lon: '',
  type: UNIT_TYPES.DROP_OFF,
  unit: '',
  noteData: '',
  organizationUId: '',
  showOnMap: false,
  title: '',
  openingHours: {
    monday: {
      from: 540,
      to: 1020,
      is24HourOpening: false,
      isClosed: false,
    },
    tuesday: {
      from: 540,
      to: 1020,
      is24HourOpening: false,
      isClosed: false,
    },
    wednesday: {
      from: 540,
      to: 1020,
      is24HourOpening: false,
      isClosed: false,
    },
    thursday: {
      from: 540,
      to: 1020,
      is24HourOpening: false,
      isClosed: false,
    },
    friday: {
      from: 540,
      to: 1020,
      is24HourOpening: false,
      isClosed: false,
    },
    saturday: {
      from: 540,
      to: 1020,
      is24HourOpening: false,
      isClosed: false,
    },
    sunday: {
      from: 540,
      to: 1020,
      is24HourOpening: false,
      isClosed: false,
    },
    customDays: [],
  },
};

export const useLocationMutation = (initialValues: Partial<LocationFields> = {}) => {
  const navigate = useNavigate();

  const inputFieldsArray = [
    ADD_LOCATION_FIELDS.ZIP,
    ADD_LOCATION_FIELDS.CITY,
    ADD_LOCATION_FIELDS.ADDRESS,
    ADD_LOCATION_FIELDS.ORGANIZATIONUID,
    ADD_LOCATION_FIELDS.LAT,
    ADD_LOCATION_FIELDS.LON,
    ADD_LOCATION_FIELDS.UNIT_TYPE,
    ADD_LOCATION_FIELDS.UNIT,
    ADD_LOCATION_FIELDS.NOTE_DATA,
    ADD_LOCATION_FIELDS.SHOW_ON_MAP,
    ADD_LOCATION_FIELDS.TITLE,
    ADD_LOCATION_FIELDS.OPENING_HOURS,
  ];

  const initialAddNewLocationFields = {
    ...defaultAddNewLocationFields,
    ...initialValues,
  };

  const formHook = useForm<LocationFields>(
    AddNewLocationSchema,
    initialAddNewLocationFields,
    false,
  );

  const { form, toastFormErrors, formErrors, isFormValid, validate } = formHook;
  const [addNewLocation, { isLoading }] = useAddNewLocationMutation();

  useEffect(() => {
    if (!isFormValid) {
      toastFormErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const addNewLocationHandler = async (event: FormEvent) => {
    event.preventDefault();
    const errors = await validate();

    if (!errors) {
      const body: AddNewLocationBody = {
        organizationUId: form.organizationUId,
        location: {
          coordinates: {
            lat: +form.lat,
            lon: +form.lon,
          },
        },
        type: form.type,
        noteData: form.noteData,
        showOnMap: form.showOnMap,
        title: form.title,
        address: {
          city: form.city,
          address: form.address,
          zipCode: form.zip,
        },
        openingHours: form.openingHours,
      };
      const res = await addNewLocation(body).unwrap();
      if (res) {
        navigate(`${PATHS.LOCATIONS}/${LOCATIONS_STATUSES.ACTIVE}`);
      }
    }
  };

  return { addNewLocationHandler, inputFieldsArray, formHook, isLoading };
};
