/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ACCOUNTS_LIST_SORT_OPTION_TABLE_KEYS, AccountsListState } from 'modules/accounts/types';
import { emptySearchAndFilters, ORDER } from 'modules/common';

const initialState: AccountsListState = {
  page: 1,
  sorting: { sort: ACCOUNTS_LIST_SORT_OPTION_TABLE_KEYS.ACCOUNT_SINCE, order: ORDER.DESC },
  searchAndFilters: { ...emptySearchAndFilters },
};

export const accountsListSlice = createSlice({
  name: 'accountsList',
  initialState,
  reducers: {
    setAccountsListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setAccountsListPage: (state, { payload }) => {
      state.page = payload;
    },
    setAccountsSearchAndFilters: (state, { payload }) => {
      state.searchAndFilters = payload;
    },
  },
});

export const accountsListReducer = accountsListSlice.reducer;

export const { setAccountsListSorting, setAccountsListPage, setAccountsSearchAndFilters } =
  accountsListSlice.actions;

export const selectAccountsList = (state: RootState) => state.accountsList;
