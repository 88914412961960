/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ORDER } from 'modules/common';
import { DEVICES_LIST_SORT_OPTION_TABLE_KEYS, DevicesListState } from 'modules/devices/types';

const initialState: DevicesListState = {
  page: 1,
  sorting: { sort: DEVICES_LIST_SORT_OPTION_TABLE_KEYS.IN_SERVICE, order: ORDER.DESC },
};

export const devicesListSlice = createSlice({
  name: 'devicesList',
  initialState,
  reducers: {
    setDevicesListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setDevicesListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const devicesListReducer = devicesListSlice.reducer;

export const { setDevicesListSorting, setDevicesListPage } = devicesListSlice.actions;

export const selectDeviceList = (state: RootState) => state.devicesList;
