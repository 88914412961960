import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const reportsModule = () => import('modules/reports');

export const reportsRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      { path: PATHS.REPORTS_DAILY, lazy: lazyImportComponent(reportsModule, 'ReportsList') },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.REPORTS_ACTIVITIES,
        lazy: lazyImportComponent(reportsModule, 'DailyActivitiesSummary'),
      },
    ],
  },
];
