import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const certificatesModule = () => import('modules/certificates');

export const certificatesRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.CERTIFICATES_LIST,
        lazy: lazyImportComponent(certificatesModule, 'CertificatesListScreen'),
      },
    ],
  },
];
