import { quickDropAdminApi } from 'config/api';
import { ACTIVITIES_API_URLS } from 'modules/activities/constants';
import {
  MoneyTransfersList,
  MoneyTransfersByOperatorUIdListQueryParams,
  MoneyTransferDetail,
} from 'modules/activities/types';
import { ORDER, REQUEST_METHODS } from 'modules/common';

export const moneyTransfersApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['MoneyTransfersList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMoneyTransfersByOperatorUId: builder.query<
        MoneyTransfersList,
        MoneyTransfersByOperatorUIdListQueryParams
      >({
        query: ({ operatorUId, order = ORDER.ASC, sort, page, take, filters }) => {
          const params = {
            ...filters,
            'pagination[page]': page,
            'pagination[take]': take,
            'filters[organizationUId][in][]': operatorUId,
            [`sort[${sort}]`]: order,
          };

          return {
            url: ACTIVITIES_API_URLS.MONEY_TRANSFERS,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (result ? ['MoneyTransfersList'] : []),
      }),
      getMoneyTransferDetails: builder.query<MoneyTransferDetail, string>({
        query: (transferId) => ({
          url: ACTIVITIES_API_URLS.MONEY_TRANSFER_DETAILS(transferId),
          method: REQUEST_METHODS.GET,
        }),
      }),
    }),
  });

export const { useGetMoneyTransfersByOperatorUIdQuery, useGetMoneyTransferDetailsQuery } =
  moneyTransfersApi;
