import { quickDropAdminApi } from 'config/api';
import { ACTIVITIES_API_URLS } from 'modules/activities/constants';
import {
  DropOffSession,
  DropOffSessionList,
  DropOffSessionLogInfoList,
  DropOffSessionsByOperatorUIdListQueryParams,
} from 'modules/activities/types';
import { LogInfoQueryParams, ORDER, REQUEST_METHODS } from 'modules/common';

export const dropOffSessionsApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['DropOffSessionList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDropOffSessionsByOperatorUId: builder.query<
        DropOffSessionList,
        DropOffSessionsByOperatorUIdListQueryParams
      >({
        query: ({ operatorUId, order = ORDER.ASC, sort, page, take, deviceId }) => {
          const params = {
            'filters[organizationUId][$in][]': operatorUId,
            'pagination[page]': page,
            'pagination[take]': take,
            [`sort[${sort}]`]: order,
          };
          if (deviceId) {
            params['filters[deviceId][$in][]'] = deviceId;
          }

          return {
            url: ACTIVITIES_API_URLS.DROP_OFF_SESSION_LIST_BY_OPERATORUID,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (result ? ['DropOffSessionList'] : []),
      }),
      getDropOffSessionById: builder.query<DropOffSession, string>({
        query: (sessionId) => ({
          url: ACTIVITIES_API_URLS.DROP_OFF_SESSION_DETAILS(sessionId),
          method: REQUEST_METHODS.GET,
        }),
      }),
      getDropOffSessionLogInfo: builder.query<DropOffSessionLogInfoList, LogInfoQueryParams>({
        query: ({ page, id }) => {
          const params = {
            'pagination[page]': page,
            'filters[id]': id,
          };
          return {
            url: ACTIVITIES_API_URLS.DROP_OFF_SESSION_LOG_INFO,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      refetchDropOffSessionsList: builder.mutation<null, void>({
        queryFn: () => ({ data: null }),
        invalidatesTags: ['DropOffSessionList'],
      }),
    }),
  });

export const {
  useGetDropOffSessionsByOperatorUIdQuery,
  useGetDropOffSessionByIdQuery,
  useLazyGetDropOffSessionLogInfoQuery,
  useRefetchDropOffSessionsListMutation,
} = dropOffSessionsApi;
