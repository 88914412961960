export const ACTIVITIES_API_URLS = {
  DROPPED_BAGS_LIST: '/recycle/dropped-bag/list',
  DROP_OFF_SESSION_LIST_BY_OPERATORUID: '/bag-handover/list',
  DROP_OFF_SESSION_DETAILS: (sessionId: string) => `/bag-handover/${sessionId}`,
  DROP_OFF_SESSION_LOG_INFO: '/admin/bag-handover-session-log-info',
  MONEY_TRANSFERS: '/financial/payout',
  MONEY_TRANSFER_DETAILS: (id: string) => `/financial/payout/${id}`,
  DROPPED_BAG_DETAILS: (id: string) => `/recycle/dropped-bag/get/${id}`,
  DROPPED_BAG_NOTE_UPDATE: (id: string) => `/recycle/dropped-bag/update/${id}`,
  BAGS_PURCHASED_LIST: '/financial/bag-purchase',
  BAGS_PURCHASED_DETAILS: (id: string) => `/financial/bag-purchase/details/super/${id}`,
  BAG_DEPOSITED_LOG_INFO: '/admin/dropped-bag-log-info',
  ADD_CUSTOMER_OR_DEVICE_TO_BAG: 'recycle/dropped-bag/add-customer-or-device-to-bag',
};

export const DROP_OFF_SESSION_PER_PAGE = 15;

export const MONEY_TRANSFERS_PER_PAGE = 15;
