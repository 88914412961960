import { quickDropAdminApi } from 'config/api';
import { REQUEST_METHODS } from 'modules/common';
import { PROCESSING_FEE_API_URLS } from 'modules/processing-fee/constants';
import { resetProcessingFeeLogs, setProcessingFeeLogsPage } from 'modules/processing-fee/store';
import {
  ProcessingFeeChange,
  ProcessingFee,
  ProcessingFeeLogList,
  ProcessingFeeLogInfoQueryParams,
} from 'modules/processing-fee/types';

export const processingFeeApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['ProcessingFeeLogInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCurrentProcessingFee: builder.query<ProcessingFee, void>({
        query: () => ({
          url: PROCESSING_FEE_API_URLS.BASE_URL,
          method: REQUEST_METHODS.GET,
        }),
        extraOptions: { disableDefaultSuccessToaster: true },
      }),
      addNewProcessingFee: builder.mutation<ProcessingFee, ProcessingFeeChange>({
        query: (body) => ({
          body,
          url: PROCESSING_FEE_API_URLS.BASE_URL,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['ProcessingFeeLogInfo']),
        onQueryStarted(newFeeChange, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            processingFeeApi.util.updateQueryData('getCurrentProcessingFee', undefined, () => {
              return { fee: newFeeChange.fee, createdAt: new Date().toISOString() };
            }),
          );
          queryFulfilled
            .then(() => {
              dispatch(setProcessingFeeLogsPage(1));
              dispatch(resetProcessingFeeLogs());
            })
            .catch(updatedResult.undo);
        },
      }),
      getProcessingFeeLogInfo: builder.query<ProcessingFeeLogList, ProcessingFeeLogInfoQueryParams>(
        {
          query: ({ page }) => {
            const params = {
              'pagination[page]': page,
            };
            return {
              url: `${PROCESSING_FEE_API_URLS.BASE_URL}${PROCESSING_FEE_API_URLS.GET_INFO}`,
              params,
              method: REQUEST_METHODS.GET,
            };
          },
          providesTags: (_result, error) => (error ? [] : ['ProcessingFeeLogInfo']),
        },
      ),
    }),
  });

export const {
  useGetCurrentProcessingFeeQuery,
  useAddNewProcessingFeeMutation,
  useLazyGetProcessingFeeLogInfoQuery,
} = processingFeeApi;
