import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from 'assets/locales';

const resources = {
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init(
  {
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  },
  () => i18n.language,
);
// eslint-disable-next-line import/no-default-export
export default i18n;
