import { BAG_ITEM_PRICE_TYPE } from 'modules/activities/types';
import { ORDER, PageInfo, Sorting } from 'modules/common';

export enum MATERIAL_TYPE {
  ALL = 'All',
  PET = 'Plastic 1 (PET)',
  HDPE = 'Plastic 2 (HDPE)',
  PVC = 'Plastic 3 (PVC)',
  LDPE = 'Plastic 4 (LDPE)',
  PP = 'Plastic 5 (PP)',
  PS = 'Plastic 6 (PS)',
  OTHER = 'Plastic 7 (Other)',
  AL = 'Aluminium',
  BIMETAL = 'Bimetal',
  GLASS = 'Glass',
  BOX = 'Box',
  POUCH_CART = 'Pouch Cart',
}

export enum RECYCLABLE_TYPE {
  AL = 'AL',
  PET = 'PET',
  HDPE = 'HDPE',
  PVC = 'PVC',
  LDPE = 'LDPE',
  PP = 'PP',
  PS = 'PS',
  OTHER = 'OTHER',
  BIMETAL = 'BIMETAL',
  GLASS = 'GLASS',
  BOX = 'BOX',
  POUCH_CART = 'POUCH_CART',
}

export interface AmountDetails {
  priceType: BAG_ITEM_PRICE_TYPE;
  weight: number;
  calculationPrice: number;
  statePrice?: number;
  piecesCount?: number;
  fiveCentExchangeRate?: number;
  amount: number;
}

export interface DailySummaryTableEntry {
  certificateUId: string;
  createdAt: string;
  type: string;
  crvWeight: number;
  srpWeight: number;
  weight: number;
  refundValue: number;
}

export interface DailySummary {
  _id: string;
  dailyLoadLimit: number;
  amountDetails: AmountDetails[];
  overLimit: number;
  createdAt: string;
  bagId: string;
  srpWeight: number;
  crvWeight: number;
  certificateUId: string;
  organizationUId: string;
  refundValue: number;
  type: RECYCLABLE_TYPE;
  weight: number;
  totalAmount: number;
  priceType: BAG_ITEM_PRICE_TYPE;
}

export interface DailySummaryResponse {
  data: DailySummary[];
  meta: PageInfo;
}

export interface DailyActivitiesSummary {
  fromDate: string;
  toDate: string;
  numberOfdroppedBags: number;
  numberOfReceivedBags: number;
  numberOfCreditedBags: number;
  numberOfNonCreditedBagsForMoreThanThreeDays: number;
  numberOfBagsWithQualityIssues: number;
  numberOfNewSignUps: number;
  uniqueCustomersDroppedBags: number;
  numberOfUniqueDroppedBags: number;
  usersWithOverLimit: [];
}

export interface DailyActivitiesSummaryResponse {
  data: DailyActivitiesSummary[];
}

export interface DailyActivitiesSummaryQueryParams {
  from?: string;
  until?: string;
  organizationUId?: string;
}

export enum DAILY_SUMMARY_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  CERTIFICATE_UID = 'certificateUId',
  TYPE = 'type',
}

export interface DailySummaryReportsQueryParams {
  page?: number;
  take?: number;
  sort?: DAILY_SUMMARY_SORT_OPTIONS;
  order?: ORDER;
  from?: string;
  until?: string;
  certificate?: string;
  type?: MATERIAL_TYPE;
  organizationUId?: string;
}

export interface DailyActivitiesSummaryReportsQueryParams {
  from?: string;
  until?: string;
  organizationUId?: string;
}

export enum DAILY_SUMMARY_SORT_OPTION_TABLE_KEYS {
  CREATED_AT = 'createdAt',
  CERTIFICATE_UID = 'certificateUId',
  TYPE = 'type',
}

export type DailySummarySorting = Sorting<DAILY_SUMMARY_SORT_OPTIONS>;

export const dailySummariesSortOptions: Record<
  DAILY_SUMMARY_SORT_OPTION_TABLE_KEYS,
  DAILY_SUMMARY_SORT_OPTIONS
> = {
  createdAt: DAILY_SUMMARY_SORT_OPTIONS.CREATED_AT,
  certificateUId: DAILY_SUMMARY_SORT_OPTIONS.CERTIFICATE_UID,
  type: DAILY_SUMMARY_SORT_OPTIONS.TYPE,
};

export interface DailySummaryState {
  page: number;
  sorting: DailySummarySorting;
  filters: {
    from?: string;
    until?: string;
    certificate?: string;
    type?: MATERIAL_TYPE;
  };
}
export interface DailyActivitiesSummaryState {
  filters: {
    from?: string;
    until?: string;
  };
}
