import { LocationWithAddress } from 'modules/locations';
import { NOTIFICATION_PLATFORMS, SEND_TO_TYPE } from 'modules/notifications/types';

export enum REQUEST_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface RequestError {
  status: number;
  data: {
    statusCode: number;
    message: string;
  };
}

export const IP_RESTRICTION_CAUSE = 'ip_restriction';

export interface PageInfo {
  page: number;
  take: number;
  lastPage: number;
  total: number;
}

export enum ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Sorting<T> {
  sort: T;
  order: ORDER;
}

export enum REGEX_OPTIONS {
  CASE_INSENSITIVE = 'i',
}

export interface Operator {
  name: string;
  organizationUId: string;
}

export enum TEXT_ALIGNMENTS {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center',
}

export interface OptionsForTableRows {
  forceToHideViewButton?: boolean;
  classNameForRow?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericTableEntry = Record<string, any> & OptionsForTableRows;

export interface SortingOptions {
  sortableHeaders?: string[];
  setSorting?: CallableFunction;
  currentSorting?: { sort: string; order: ORDER };
}

export interface TableOptions {
  withIndexing?: boolean;
  sortingOptions?: SortingOptions;
  onExport?: () => void;
  onView?: (rowData: GenericTableEntry) => void;
  onDelete?: (rowData: GenericTableEntry) => void;
  allBorders?: boolean;
  noBordersOnHeader?: boolean;
  translateHeaders?: boolean;
  rowHeight?: string;
  alignText?: TEXT_ALIGNMENTS;
  tbodyClass?: string;
  hideHeaders?: boolean;
}

export type TableColumn = string | { label: string; className: string; translationKey?: string };
export type TableColumnTyped<T> = T | { label: T; className: string; translationKey?: string };
export interface DateRange {
  startDate?: string;
  endDate?: string;
}

export enum QUERY_FILTER_PARAM_KEYS {
  OR_BAG_ID_SEARCH = 'filters[or][bagId][$regex]',
  OR_BAG_ID_SEARCH_OPTIONS = 'filters[or][bagId][$options]',
  OR_USER_UID_SEARCH = 'filters[or][userUId][$regex]',
  OR_USER_UID_SEARCH_OPTIONS = 'filters[or][userUId][$options]',
  OR_USER_UID_OF_USER_SEARCH = 'filters[or][user.userUId][$regex]',
  OR_USER_UID_OF_USER_SEARCH_OPTIONS = 'filters[or][user.userUId][$options]',
  USER_UID_OF_USER_SEARCH_EXACT_MATCH = 'filters[user.userUId][$in][]',
  SQL_OR_USER_UID_SEARCH = 'filters[userUId][equals]',
  OR_FIRST_NAME_SEARCH = 'filters[or][firstName][$regex]',
  OR_FIRST_NAME_SEARCH_OPTIONS = 'filters[or][firstName][$options]',
  OR_LAST_NAME_SEARCH = 'filters[or][lastName][$regex]',
  OR_LAST_NAME_SEARCH_OPTIONS = 'filters[or][lastName][$options]',
  OR_EMAIL_SEARCH = 'filters[or][email][$regex]',
  OR_EMAIL_SEARCH_OPTIONS = 'filters[or][email][$options]',
  OR_PHONE_NUMBER_SEARCH = 'filters[or][phoneNumber][$regex]',
  OR_PHONE_NUMBER_SEARCH_OPTIONS = 'filters[or][phoneNumber][$options]',
  OR_NAME_SEARCH = 'filters[or][name][$regex]',
  OR_NAME_SEARCH_OPTIONS = 'filters[or][name][$options]',
  OR_IP_ADDRESS_SEARCH = 'filters[or][ip][$regex]',
  OR_IP_ADDRESS_SEARCH_OPTIONS = 'filters[or][ip][$options]',
  CREATED_AT_GT = 'filters[createdAt][$gt]',
  CREATED_AT_LT = 'filters[createdAt][$lt]',
  CREATED_AT_GT_SQL = 'filters[createdAt][gt]',
  CREATED_AT_LT_SQL = 'filters[createdAt][lt]',
  CREATED_AT_NE = 'filters[createdAt][$ne]',
  TOTAL_BAGS_SCANNED_GT = 'filters[totalBagsScanned][$gt]',
  TOTAL_BAGS_SCANNED_LT = 'filters[totalBagsScanned][$lt]',
  TOTAL_BAGS_SCANNED_NE = 'filters[totalBagsScanned][$ne]',
  TOTAL_BAGS_TODAY_GT = 'filters[totalBagsToday][$gt]',
  TOTAL_BAGS_TODAY_LT = 'filters[totalBagsToday][$lt]',
  TOTAL_BAGS_TODAY_NE = 'filters[totalBagsToday][$ne]',
  RECEIVED_AT_NE = 'filters[receivedAt][$ne]',
  RECEIVED_AT_EQ = 'filters[receivedAt][$eq]',
  RECEIVED_AT_GT = 'filters[receivedAt][$gt]',
  RECEIVED_AT_GT_SQL = 'filters[receivedAt][gt]',
  RECEIVED_AT_LT = 'filters[receivedAt][$lt]',
  RECEIVED_AT_LT_SQL = 'filters[receivedAt][lt]',
  PROCESSED_AT_GT = 'filters[processedAt][$gt]',
  PROCESSED_AT_GT_SQL = 'filters[processedAt][gt]',
  PROCESSED_AT_LT = 'filters[processedAt][$lt]',
  PROCESSED_AT_LT_SQL = 'filters[processedAt][lt]',
  PROCESSED_AT_NE = 'filters[processedAt][$ne]',
  PROCESSED_AT_EQ = 'filters[processedAt][$eq]',
  CREDITED_AT_GT = 'filters[creditedAt][$gt]',
  CREDITED_AT_GT_SQL = 'filters[creditedAt][gt]',
  CREDITED_AT_LT = 'filters[creditedAt][$lt]',
  CREDITED_AT_NE = 'filters[creditedAt][$ne]',
  CREDITED_AT_EQ = 'filters[creditedAt][$eq]',
  OR_QUALITY_ISSUES_EXISTS = 'filters[or][qualityIssues][$exists]',
  OR_QUALITY_ISSUES_NOT_SIZE = 'filters[or][qualityIssues][$not][$size]',
  OR_ISSUES_EXISTS = 'filters[or][issues][$exists]',
  OR_ISSUES_NOT_SIZE = 'filters[or][issues][$not][$size]',
  BAG_ID_EXIST = 'filters[bagId][$exists]',
  UNPAID_BAGS_GT = 'filters[unpaidBags][$gt]',
  OPEN_TRANSACTION_REQUEST_GT = 'filters[openTransactionRequestsAmount][$gt]',
  NOR_UNPAID_BAGS_GT = 'filters[nor][unpaidBags][$gt]',
  NOR_UNPAID_BAGS_EXISTS = 'filters[nor][unpaidBags][$exists]',
  OR_ACCEPTED_AT_LOCATION_IN = 'filters[or][dropOffLocation.locationUId][$in][]',
  OR_RECEIVED_AT_LOCATION_IN = 'filters[or][receivedLocation.locationUId][$in][]',
  OR_PROCESSED_AT_LOCATION_IN = 'filters[or][processedLocation.locationUId][$in][]',
  DEVICE_ID = 'filters[deviceId][$in][]',
  BAG_STATUS = 'filters[status][$in][]',
}

export type QueryFilterParams = Partial<Record<QUERY_FILTER_PARAM_KEYS, string>>;

export enum BAG_STATUSES {
  DROPPED = 'dropped',
  RECEIVED = 'received',
  PROCESSED = 'processed',
  CREDITED = 'credited',
}

export enum PAID_STATUS {
  PAID = 'paid',
  UNPAID = 'unpaid',
  OVER_3_DAYS_UNPAID = 'over3daysUnpaid',
}

export enum DATE_RANGE_TYPES {
  TODAY = 'today',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  LAST_90_DAYS = 'last90Days',
  DATE_RANGE = 'dateRange',
}

export enum BAG_OPTIONS {
  MISSING_ID = 'missingId',
  ISSUES_FOUND = 'issuesFound',
}

export enum UNPAID_BAGS_OPTIONS {
  HAS_UNPAID_BAGS = 'hasUnpaidBags',
  DOES_NOT_HAVE_UNPAID_BAGS = 'doesNotHaveUnpaidBags',
}

export enum TRANSACTION_REQUESTS_OPTIONS {
  HAS_OPEN_TRANSACTION_REQUEST = 'hasOpenTransactionRequest',
}

export interface AddFilterState {
  paid: PAID_STATUS | null;
  bag: BAG_OPTIONS | null;
  bagsStatus: BAG_STATUSES | null;
  droppedBagLocation: LocationWithAddress | null;
  dateRangeType: DATE_RANGE_TYPES | null;
  dateRange: DateRange | null;
  unpaidBags: UNPAID_BAGS_OPTIONS | null;
  transactionRequests: TRANSACTION_REQUESTS_OPTIONS | null;
  deviceId: string | null;
}

export enum FILTER_CHECKBOX_OPTIONS {
  CUSTOMER_ID = 'customerId',
  CUSTOMER_ID_OF_USER_EXACT_MATCH = 'customerIdOfUserExactMatch',
  CUSTOMER_ID_OF_USER = 'customerIdOfUser',
  BAG_ID = 'bagId',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  NAME = 'name',
  IP_ADDRESS = 'ip',
}

export interface SearchAndFiltersState {
  filterText: string;
  filterCheckbox: FILTER_CHECKBOX_OPTIONS[];
  isOptionsVisible: boolean;
  addFilter: AddFilterState;
}

export type FilterOptions = (keyof AddFilterState)[];

export interface SearchOptions {
  searchBarCheckboxes: FILTER_CHECKBOX_OPTIONS[];
  filterOptions: FilterOptions;
}

export enum SCANNING_DEVICE_TYPE {
  CONTAINER = 'container',
  BAG_SCANNER = 'bag_scanner',
}

export interface UserInfo {
  email: string;
  firstName: string;
  lastName: string;
}

export enum LOG_TYPE {
  MODIFICATION = 'modification',
  COMMENT = 'comment',
  NOTIFICATION = 'notification',
}

export interface NoteData {
  userUId?: string;
  firstName?: string;
  lastName?: string;
  text: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface BaseLogInfo {
  _id: string;
  type: LOG_TYPE;
  comment?: string;
  loggingUser?: {
    userUId: string;
    firstName: string;
    lastName: string;
  };
  createdAt: string;
}

export interface NotificationOnLog {
  sendToType: SEND_TO_TYPE;
  sendToUserUId: string;
  titleText: string;
  bodyText: string;
  acceptText?: string;
  platformType: NOTIFICATION_PLATFORMS;
  customNotificationId: string;
}

export interface LogInfo<T> extends BaseLogInfo {
  modification?: T;
  notification?: NotificationOnLog;
}

export interface LogInfoQueryParams {
  page?: number;
  id: string;
}
export interface CommentPostRequest {
  id: string;
  comment: string;
}

export interface AddCustomerOrDeviceToBagRequest {
  userUId?: string;
  deviceId?: string;
  bagId: string;
}

export interface OpeningHoursDay {
  from: number;
  to: number;
  isClosed?: boolean;
  is24HourOpening?: boolean;
}

export interface OpeningHoursCustomDay extends OpeningHoursDay {
  date: string;
}

export interface OpeningHours {
  monday: OpeningHoursDay;
  tuesday: OpeningHoursDay;
  wednesday: OpeningHoursDay;
  thursday: OpeningHoursDay;
  friday: OpeningHoursDay;
  saturday: OpeningHoursDay;
  sunday: OpeningHoursDay;
  customDays?: OpeningHoursCustomDay[];
}

export const openingHoursDaysOfWeek: (keyof Omit<OpeningHours, 'customDays'>)[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
