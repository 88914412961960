/* eslint-disable @typescript-eslint/no-explicit-any */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export function isErrorWithMessageArray(error: unknown): error is { message: string[] } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message !== 'string' &&
    (error as any).message?.length > 0
  );
}

export function convertErrorMessagesArrayToString(message: string[]): string {
  return message
    .map((msg) => {
      if (typeof msg === 'string' && msg.length > 0) {
        return msg[0].toUpperCase() + msg.slice(1);
      }
      return msg;
    })
    .join('\n');
}
