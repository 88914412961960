import { quickDropAdminApi } from 'config/api';
import { REQUEST_METHODS } from 'modules/common';
import { STATE_PRICES_API_URLS } from 'modules/prices/constants';
import {
  StateRateChange,
  StateRate,
  StateRateList,
  StateRateLogList,
  RateLogInfoQueryParams,
} from 'modules/prices/types';

export const stateRatesApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['StateRate', 'OperatorRate', 'StateRateLogInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getStateRates: builder.query<StateRate[], void>({
        query: () => ({
          url: STATE_PRICES_API_URLS.STATE_RATES,
          method: REQUEST_METHODS.GET,
        }),
        transformResponse: (data: StateRateList) =>
          Object.keys(data)
            .map((key) => data[+key])
            .sort((a, b) => a.itemType.localeCompare(b.itemType)),
        providesTags: (_result, error) => (error ? [] : ['StateRate']),
      }),
      updateStateRate: builder.mutation<StateRate, StateRateChange[]>({
        query: (stateRates) => ({
          body: { stateRates },
          url: STATE_PRICES_API_URLS.STATE_RATES,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : ['StateRate', 'OperatorRate', 'StateRateLogInfo'],
      }),
      getStateRatesLogInfo: builder.query<StateRateLogList, RateLogInfoQueryParams>({
        query: ({ page }) => {
          const params = {
            'pagination[page]': page,
            'filter[createdAt][$ne]': null,
          };
          return {
            url: STATE_PRICES_API_URLS.STATE_RATES_HISTORY,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['StateRateLogInfo']),
      }),
    }),
  });

export const {
  useGetStateRatesQuery,
  useUpdateStateRateMutation,
  useLazyGetStateRatesLogInfoQuery,
} = stateRatesApi;
