import { quickDropAdminApi } from 'config/api';
import { ACTIVITIES_API_URLS } from 'modules/activities/constants';
import {
  BagsPurchasedListQueryParams,
  BagsPurchasedList,
  BagsPurchasedDetails,
} from 'modules/activities/types';
import { ORDER, REQUEST_METHODS } from 'modules/common';

export const bagsPurchasedApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['BagsPurchasedList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBagsPurchasedList: builder.query<BagsPurchasedList, BagsPurchasedListQueryParams>({
        query: ({ operatorUId, order = ORDER.ASC, sort, page, take, deviceId, userUId }) => {
          const params = {
            'pagination[page]': page,
            'pagination[take]': take,
            'filters[organizationUId][in][]': operatorUId,
            'filters[deviceId][in][]': deviceId,
            'filters[userUId][in][]': userUId,
            [`sort[${sort}]`]: order,
          };

          return {
            url: ACTIVITIES_API_URLS.BAGS_PURCHASED_LIST,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (result ? ['BagsPurchasedList'] : []),
      }),
      getBagsPurchasedById: builder.query<BagsPurchasedDetails, string>({
        query: (id) => ({
          url: ACTIVITIES_API_URLS.BAGS_PURCHASED_DETAILS(id),
          method: REQUEST_METHODS.GET,
        }),
      }),
      refetchBagsPuchasedList: builder.mutation<null, void>({
        queryFn: () => ({ data: null }),
        invalidatesTags: ['BagsPurchasedList'],
      }),
    }),
  });

export const {
  useGetBagsPurchasedListQuery,
  useGetBagsPurchasedByIdQuery,
  useRefetchBagsPuchasedListMutation,
} = bagsPurchasedApi;
