import { quickDropAdminApi } from 'config/api';
import { CommentPostRequest, LogInfoQueryParams, REQUEST_METHODS } from 'modules/common';
import { LOCATIONS_API_URLS } from 'modules/locations/constants';
import { resetLocationLogs, setLocationLogsListPage } from 'modules/locations/store';
import {
  AddNewLocationBody,
  AddNewLocationResponse,
  LocationDetailsItem,
  LocationLogInfo,
  LocationLogInfoList,
  LocationsList,
  LocationsListQueryParams,
  UpdateLocationBody,
} from 'modules/locations/types';

import { customDateTransformerFromServer, customDateTransformerToServer } from './transformations';

export const locationsApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['Location', 'Devices', 'BagsDeposited', 'LocationLogInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLocations: builder.query<LocationsList, LocationsListQueryParams>({
        query: ({ page, sort, order, status, search, take, organizationUId }) => {
          const params = {
            'pagination[page]': page,
            'pagination[take]': take,
            [`sort[${sort}]`]: order,
            'filters[status][$in][]': status,
            'filters[organizationUId][$in][]': organizationUId,
            'filters[search]': search,
          };

          return {
            url: LOCATIONS_API_URLS.LOCATIONS_LIST,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (!result ? [] : ['Location']),
      }),
      getLocation: builder.query<LocationDetailsItem, string>({
        query: (id: string) => ({
          url: LOCATIONS_API_URLS.GET_LOCATION(id),
          method: REQUEST_METHODS.GET,
        }),
        transformResponse: (response: LocationDetailsItem) => {
          const { openingHours, ...rest } = response;
          const transformedCustomDays = customDateTransformerFromServer(openingHours.customDays);
          return { ...rest, openingHours: { ...openingHours, customDays: transformedCustomDays } };
        },
        providesTags: (result) => (!result ? [] : ['Location']),
      }),
      updateLocation: builder.mutation<AddNewLocationResponse, UpdateLocationBody>({
        query: (body) => {
          const { id, openingHours, ...rest } = body;

          const transformedCustomDays = customDateTransformerToServer(openingHours?.customDays);
          const transformedOpeningHours = { ...openingHours, customDays: transformedCustomDays };

          return {
            body: { ...rest, openingHours: transformedOpeningHours },
            url: LOCATIONS_API_URLS.UPDATE_LOCATION(id),
            method: REQUEST_METHODS.PATCH,
          };
        },
        invalidatesTags: (_result, error, args) => {
          const tagsToInvalidate: ('Location' | 'Devices' | 'BagsDeposited' | 'LocationLogInfo')[] =
            [];
          if (!error) {
            tagsToInvalidate.push('Location', 'LocationLogInfo', 'Devices');
            if (args.address || args.title) {
              tagsToInvalidate.push('Devices', 'BagsDeposited');
            }
          }
          return tagsToInvalidate;
        },
        onQueryStarted({ id, ...update }, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            locationsApi.util.updateQueryData('getLocation', id, (results) => {
              let updateBody: object = update;
              if (update.location) {
                const { location, ...rest } = update;
                updateBody = {
                  location: {
                    coordinates: [location.coordinates.lon, location.coordinates.lat],
                  },
                  ...rest,
                };
              }
              if (update.address) {
                updateBody = {
                  ...updateBody,
                  address: { ...results.address, ...update.address },
                };
              }
              return { ...results, ...updateBody };
            }),
          );
          queryFulfilled
            .then(() => {
              dispatch(setLocationLogsListPage(1));
              dispatch(resetLocationLogs());
            })
            .catch(updatedResult.undo);
        },
      }),
      addNewLocation: builder.mutation<AddNewLocationResponse, AddNewLocationBody>({
        query: ({ noteData, ...body }) => ({
          body: { ...body, noteData: { text: noteData } },
          url: LOCATIONS_API_URLS.LOCATIONS_ADD,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['Location']),
      }),
      getLocationLogInfo: builder.query<LocationLogInfoList, LogInfoQueryParams>({
        query: ({ page, id }) => {
          const params = {
            'pagination[page]': page,
            'filters[id]': id,
          };
          return {
            url: LOCATIONS_API_URLS.LOCATION_LOG_INFO,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['LocationLogInfo']),
      }),
      addLocationComment: builder.mutation<LocationLogInfo, CommentPostRequest>({
        query: (body) => ({
          url: LOCATIONS_API_URLS.LOCATION_LOG_INFO,
          body,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['LocationLogInfo']),
      }),
    }),
  });

export const {
  useGetLocationsQuery,
  useAddNewLocationMutation,
  useGetLocationQuery,
  useUpdateLocationMutation,
  useLazyGetLocationLogInfoQuery,
  useAddLocationCommentMutation,
} = locationsApi;
